import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}api`;

const axiosInstance = axios.create({
  baseURL: API_URL
});

export const BuildHeader = () => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

const get = async (url, params = {}) => {
  BuildHeader();
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    console.error(`GET ${url} failed:`, error.response || error.message);
    throw error;
  }
};

const post = async (url, data, config = {}) => {
  BuildHeader();
  try {
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error(`POST ${url} failed:`, error.response || error.message);
    throw error;
  }
};

const patch = async (url, data) => {
  BuildHeader();
  try {
    const response = await axiosInstance.patch(url, data);
    return response.data;
  } catch (error) {
    console.error(`PATCH ${url} failed:`, error.response || error.message);
    throw error;
  }
};

const del = async (url) => {
  BuildHeader();
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error(`DELETE ${url} failed:`, error.response || error.message);
    throw error;
  }
};

export { get, post, patch, del };
