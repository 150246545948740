import React, { useState, useEffect, useRef } from "react";
import './NavBar.css';
import DropdownConfigUser from '../../components/DropdownConfigUser/DropdownConfigUser';
import { fetchUser } from "../../services/api/fetchUser";
import { fetchClientData } from "../../services/api/fetchClient";
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';

function NavBar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userData, setUserData] = useState({ photo: '', name: '' });
  const [clientData, setClientData] = useState({ brand: '' });
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { clientId } = useParams();
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current && !containerRef.current.contains(event.target) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoadingProgress(30);
        const user = await fetchUser();
        setUserData(user);

        const client = await fetchClientData(clientId);
        setClientData(client);

        setLoadingProgress(100);
      } catch (error) {
        console.error("Error fetching user or client data:", error);
        setLoadingProgress(100);
      } finally {
        setIsLoading(false);
      }
    };

    getUserData();
  }, [clientId]);

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <div className="general">
      <LoadingBar
        color='#f11946'
        progress={loadingProgress}
        onLoaderFinished={() => setLoadingProgress(0)}
      />
      {!isLoading ? (
        <>
          <div className="buttonMenu">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/12c4b6aad0d277649e95831a7b0295e510866b467769fe61d2193214a69772b8?"
              className="img"
            />
          </div>
          <div className="text">
            <div className="div-4">
                            {/* <div className="textAbout">
                Lorem ipsum dolor sit amet consectetur. Netus semper cursus imperdiet
                eget dui hendrerit urna suscipit.
              </div> */}
              <div className="div-6">
                <img
                  loading="lazy"
                  src={clientData.brand}
                  alt="Descrição da Imagem"
                  className="company-Logo"
                />
              </div>
            </div>
            <div className="Notification">
                            {/* <a href="#" className="img-2">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/be28fe4b07b79da0d9f48fd0bbd859c8b6aee79b82191fc42445d102b44f577e?"
                  alt="Descrição da Imagem"
                  className="notificationImage"
                />
              </a> */}
              <div className="div-8" onClick={toggleDropdown} ref={containerRef}>
                <div className="companyLogo">
                  <img
                    loading="lazy"
                    src={userData.photo ? userData.photo : require('../../assets/nxt_logo.jpg')}
                    className="img-3"
                  />
                  <button className="NamePersonButton">
                    {userData.name}
                  </button>
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea8d634f667f33263f2d667e3f45d98fd17931ff2ef446eb09e7bcdf1fc41f2e?"
                  className="img-4"
                />
              </div>
            </div>
          </div>
          {showDropdown && <DropdownConfigUser dropdownRef={dropdownRef} />}
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-bar">
            <LoadingBar
              color='#004080'
              progress={loadingProgress}
              onLoaderFinished={() => setLoadingProgress(0)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
