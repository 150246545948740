import { post, del, get } from './generic.api';

export const fetchPrograms = async (sourceId) => {
    try {
      const response = await get('/program/', {
            source: sourceId 
      });
      return response.results; // Corrige para retornar a propriedade results
    } catch (error) {
      console.error('Erro ao carregar programas:', error.response);
      throw error;
    }
  };