import React, { useState, useRef, useEffect, useCallback } from 'react';
import './VideoPlayer.css';
import { FaVolumeUp, FaVolumeMute, FaPlay, FaPause, FaExpand, FaCompress, FaFastForward } from 'react-icons/fa';

const MP4Player = ({ link, startSeconds, endSeconds }) => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isScreenWide, setIsScreenWide] = useState(window.innerWidth > 1025);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);

  const handlePlayPause = useCallback((event) => {
    event.stopPropagation();
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play().then(() => {
          setIsPlaying(true);
        }).catch(error => {
          console.error('Erro ao reproduzir o vídeo:', error);
        });
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  const handleTimeUpdate = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      const adjustedCurrentTime = video.currentTime - startSeconds;
      setCurrentTime(adjustedCurrentTime);
      if (endSeconds && video.currentTime >= endSeconds) {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, [startSeconds, endSeconds]);

  const handleSeek = useCallback((e) => {
    const video = videoRef.current;
    const seekTime = (duration / 100) * e.target.value;
    video.currentTime = startSeconds + Math.min(seekTime, duration);
  }, [duration, startSeconds]);

  const handleMuteToggle = useCallback((event) => {
    event.stopPropagation();
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  }, [isMuted]);

  const handleFullscreenToggle = useCallback(() => {
    const video = videoRef.current;
    if (!isFullscreen) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) { /* Firefox */
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) { /* IE/Edge */
        video.msRequestFullscreen();
      }
    } else {
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen();
        }
      }
    }
  }, [isFullscreen]);

  const handleFullscreenChange = useCallback(() => {
    setIsFullscreen(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  }, []);

  const formatTime = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleLoadedMetadata = () => {
      const video = videoRef.current;
      video.currentTime = startSeconds;
      setDuration(endSeconds ? endSeconds - startSeconds : video.duration - startSeconds);
      video.play().then(() => {
        setIsPlaying(true);
      }).catch(error => {
        console.error('Erro ao reproduzir o vídeo:', error);
      });
      setIsLoading(false);
    };

    const handleResize = () => {
      setIsScreenWide(window.innerWidth > 1025);
    };

    video.src = link;
    video.addEventListener('loadedmetadata', handleLoadedMetadata);

    video.addEventListener('timeupdate', handleTimeUpdate);
    window.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, [link, startSeconds, endSeconds, handleTimeUpdate, handleFullscreenChange]);

  const handlePlaybackRateToggle = useCallback((event) => {
    event.stopPropagation(); // Para evitar que o vídeo pare
    const video = videoRef.current;
    const newRate = playbackRate >= 2 ? 1 : playbackRate + 0.5;
    if (video) {
      video.playbackRate = newRate;
    }
    setPlaybackRate(newRate);
  }, [playbackRate]);

  return (
    <div className="video-container" onClick={handlePlayPause}>
      {isLoading && <div className="spinner"></div>}
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        preload="auto"
        width="640"
        height="360"
        playsInline
        webkit-playsinline="true"
        onClick={(e) => e.stopPropagation()}
      />
      <div className="video-controls">
        <button className="play-pause-button" onClick={handlePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="volume-control" onClick={handleMuteToggle}>
          {isMuted ? <FaVolumeMute className="volume-icon" /> : <FaVolumeUp className="volume-icon" />}
        </div>
        <div className="playback-rate-toggle" onClick={handlePlaybackRateToggle}>
          <button className="playback-rate-button">
            <FaFastForward style={{ marginRight: '5px' }} />
            {playbackRate}x
          </button>
        </div>
        <div className="time-display">
          <span className="current-time">{formatTime(currentTime)}</span>
          <span className="total-time"> / {formatTime(duration)}</span>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleSeek}
        />
        {isScreenWide && (
          <button className="fullscreen-button" onClick={handleFullscreenToggle}>
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
        )}
      </div>
    </div>
  );
};

export default MP4Player;
