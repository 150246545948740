import { post, del, get } from './generic.api';

export const fetchPresenters = async (programId) => {
    try {
      const response = await get('/presenters/', {
            source: programId 
      });
      return response.results; // Corrige para retornar a propriedade results
    } catch (error) {
      console.error('Erro ao carregar apresentadores:', error.response);
      throw error;
    }
  };