import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  const AuthHOC = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
      const token =  localStorage.getItem('token') ? localStorage.getItem('token') : searchParams.get('token');
      if (!token) {
        window.location.href = process.env.REACT_APP_LINK_URL; // Redirecionar se não houver token
      } else {
        localStorage.setItem('token', token);
      }
    }, [location, searchParams]);

    return <WrappedComponent {...props} />;
  };

  return AuthHOC;
};

export default withAuth;