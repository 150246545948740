import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Card.css';
import TelaVisualizacao from '../../pages/TelaVisualização/TelaVisualizaçao';
import ReactLoading from 'react-loading';

function Card({ clippingId, imageUrl, title, type, publicationDate, sourceName, category, hasEvaluate, clippings }) {
  const { clientId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClippingId, setSelectedClippingId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
  
    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);

  const handleCardClick = () => {
    const index = clippings.findIndex(clipping => clipping.id === clippingId);
    setSelectedClippingId(clippingId);
    setCurrentIndex(index);
    setLoading(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedClippingId(null);
    setCurrentIndex(null);
  };

  const handleNext = () => {
    if (currentIndex < clippings.length - 1) {
      setLoading(true);
      const nextIndex = currentIndex + 1;
      setSelectedClippingId(clippings[nextIndex].id);
      setCurrentIndex(nextIndex);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setLoading(true);
      const prevIndex = currentIndex - 1;
      setSelectedClippingId(clippings[prevIndex].id);
      setCurrentIndex(prevIndex);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (clientId) {
      window.open(`/${clientId}/visualizacao/${clippingId}`, '_blank');
    } else {
      console.error("clientId is undefined");
    }
  };

  const categoryList = Array.isArray(category) ? category.map(cat => cat.name).join(', ') : category;
  const statusClass = hasEvaluate ? 'evaluated' : 'not-evaluated';

  return (
    <>
      <article 
        className={`card ${statusClass}`} 
        onClick={handleCardClick}
        onContextMenu={handleContextMenu}
      >
        <header className="card-header">
          <div className="thumbnail-wrapper">
            <img src={imageUrl} alt="Thumbnail" className="thumbnail" />
          </div>
          <div className="card-content">
            <h2 className="card-title">{title}</h2>
            <div className="card-details">
              <span className="detail-value">{publicationDate}</span>
              <span className="detail-separator">|</span>
              <span className="detail-value">{type}</span>
              <span className="detail-separator">|</span>
              <span className="detail-value">{sourceName}</span>
              <span className="detail-separator">|</span>
              <span className="detail-value">{categoryList}</span>
              <span className="detail-separator">|</span>
              <span className={`detail-value ${statusClass}`}>
                {hasEvaluate ? 'Avaliado' : 'Não Avaliado'}
              </span>
            </div>
          </div>
        </header>
      </article>

      {isModalOpen && selectedClippingId && (
      <div className="modal-overlay-card">
        <div className="modal-content-card">
          <button className="modal-close" onClick={handleCloseModal}>X</button>
          {loading && (
            <div className="loading-overlay active">
              <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
            </div>
          )}
          <TelaVisualizacao 
            clippingId={selectedClippingId} 
            clientId={clientId} 
            isModal={true} 
            onLoadingComplete={() => setLoading(false)} // Callback para definir loading como falso
          />
          {!loading && (
            <div className="modal-navigation">
              <button onClick={handlePrevious} disabled={currentIndex === 0} className="nav-button left-button">
                &#8249;
              </button>
              <button onClick={handleNext} disabled={currentIndex === clippings.length - 1} className="nav-button right-button">
                &#8250;
              </button>
            </div>
          )}
        </div>
      </div>
    )}
    </>
  );
}

export default Card;
