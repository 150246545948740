import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './TelaNoticias.css';
import NavBar from '../../components/navbar/NavBar';
import Menu from '../../components/menu/Menu';
import ClippingList from '../../components/Card/ClippingList';
import FilterSearch from '../../components/FilterSearch/FilterSearch';
import Filters from '../../components/Filters/Filters';
import FiltersCategory from '../../components/FilterCategory/FilterCategory';
import CustomDate from '../../components/Date/Date';
import CardFiltrosAssuntos from '../../components/CardFiltroAssuntos/CardFiltrosAssuntos';
import { fetchClippingsByDate } from '../../services/api/fetchClipping';
import { fetchUser } from "../../services/api/fetchUser";
import { useClippings } from '../../services/ClippingsContext';
import ReactLoading from 'react-loading';
import { FaUserCircle } from 'react-icons/fa';

function TelaNoticias() {
  const {
    clippings, setClippings,
    initialFilteredData, setInitialFilteredData,
    filteredData, setFilteredData,
    filterType, setFilterType,
    selectedCategories, setSelectedCategories,
    recentFirst, setRecentFirst,
    showCategoryDropdown, setShowCategoryDropdown,
    categoryCounts, setCategoryCounts,
    selectedDropdownCategory, setSelectedDropdownCategory
  } = useClippings();

  const { clientId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalclippings, setTotalclippings] = useState(0);
  const itemsPerPage = 50;
  const [hasEvaluateFilter, setHasEvaluateFilter] = useState('todos');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [clientName, setClientName] = useState('');
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    if (initialFilteredData.length > 0) {
      applyFilters();
    }
  }, [filterType, selectedCategories, recentFirst, selectedDropdownCategory, hasEvaluateFilter]);

  const updateCategoryCounts = useCallback((data) => {
    const counts = {};
    data.forEach((item) => {
      item.categories.forEach((category) => {
        if (!counts[category.name]) {
          counts[category.name] = { count: 0, types: {} };
        }

        counts[category.name].count++;
  
        const sourceType = item.source_detail.source_type;
        if (!counts[category.name].types[sourceType]) {
          counts[category.name].types[sourceType] = 0;
        }
        counts[category.name].types[sourceType]++;
      });
    });
    setCategoryCounts(counts);
  }, [setCategoryCounts]);

  useEffect(() => {
    const today = new Date().toLocaleDateString('pt-BR');
    handleDateSelected(today);
  }, []);

  useEffect(() => {
    const getClientName = async () => {
      try {
        const clientname = await fetchUser(clientId);
        setClientName(clientname.name);
      } catch (error) {
        console.error('Failed to fetch client data:', error);
      }
    };
  
    getClientName();
  }, [clientId]);

  const parseDate = useCallback((dateString) => {
    const [day, month, year, hour, minute] = dateString.match(/(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}):(\d{2})/).slice(1);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}`);
  }, []);

  const applyFilters = useCallback(() => {
    let filtered = [...initialFilteredData];

    if (filterType !== 'todos' && filterType !== 'Assuntos') {
      filtered = filtered.filter((data) => data.source_detail.source_type === filterType);
    }

    if (selectedDropdownCategory && selectedDropdownCategory !== '') {
      filtered = filtered.filter((data) => data.categories.some(cat => cat.name === selectedDropdownCategory));
    }

    if (selectedCategories.length > 0 && filterType !== 'Assuntos') {
      filtered = filtered.filter((data) => selectedCategories.includes(data.source_detail.name));
    }

    if (hasEvaluateFilter !== 'todos') {
      const hasEvaluate = hasEvaluateFilter === 'avaliado';
      filtered = filtered.filter((data) => data.has_evaluation === hasEvaluate);
    }

    filtered.sort((a, b) => {
      const dateA = parseDate(a.publish_date);
      const dateB = parseDate(b.publish_date);
      return recentFirst ? dateB - dateA : dateA - dateB;
    });

    setFilteredData(filtered);
  }, [filterType, selectedCategories, recentFirst, selectedDropdownCategory, hasEvaluateFilter, initialFilteredData, parseDate, setFilteredData]);

  const handleFilterChange = (filterType) => {
    setFilterType(filterType);
    setSelectedCategories([]);
    setShowCategoryDropdown(filterType !== 'Assuntos');

    if (filterType === 'todos') {
      setFilteredData(initialFilteredData);
    } else {
      applyFilters();
    }
  };

  const handleCategoryFilterChange = (category) => {
    setSelectedDropdownCategory(category);
  };

  const handleCategorySelected = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handleSortChange = (sortOption) => {
    setRecentFirst(sortOption === 'opcaoA');
    applyFilters();
  };

  const handleEvaluateFilterChange = (evaluateFilter) => {
    setHasEvaluateFilter(evaluateFilter);
  };

  const handleDateSelected = async (selectedDate) => {
    try {
      setIsLoading(true);
      const {results, count} = await fetchClippingsByDate(clientId, selectedDate, selectedDate, 1, itemsPerPage);
      setClippings(results);
      setTotalclippings(count);
      setTotalPages(Math.ceil(count / itemsPerPage));
  
      setInitialFilteredData(results);
      setFilteredData(results);
      updateCategoryCounts(results);
  
      // Atualizar as datas armazenadas no localStorage
      updateStoredDates(selectedDate);
    } catch (error) {
      console.error('Failed to fetch clippings:', error);
    } finally {
      setIsLoading(false); // Fim do carregamento
    }
  };

  const handleCategoryFilter = (category, sourceType) => {
    const filtered = initialFilteredData.filter((data) => 
      data.categories.some(cat => cat.name === category) && data.source_detail.source_type === sourceType
    );
    setFilteredData(filtered);
  };

  const handleSourceNameSearch = (searchTerms) => {
    if (searchTerms.length === 0) {
      setFilteredData(initialFilteredData); 
      return;
    }
  
    const normalizeString = (str) => {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
  
    const filtered = initialFilteredData.filter((data) => 
      searchTerms.some(term => 
        normalizeString(data.source_detail.name).includes(normalizeString(term))
      )
    );
    setFilteredData(filtered);
  };
  

  const getTotalCount = useCallback((startIndex) => {
    let totalCount = 0;
    const keys = Object.keys(categoryCounts).slice(startIndex, startIndex + 5);
    keys.forEach((key) => {
      totalCount += categoryCounts[key].count;
    });
    return totalCount;
  }, [categoryCounts]);

  const getUniqueCategories = useCallback(() => {
    const categories = filteredData.flatMap(data => data.categories.map(cat => cat.name));
    return [...new Set(categories)];
  }, [filteredData]);

  const getStoredDates = () => {
    const storedDates = JSON.parse(localStorage.getItem('searchDates'));
    return storedDates || [];
  };

  const updateStoredDates = (newDate) => {
    const storedDates = getStoredDates();
    if (storedDates.length >= 2) {
      storedDates.shift(); 
    }
    storedDates.push(newDate); 
    localStorage.setItem('searchDates', JSON.stringify(storedDates));
  };

  const setInitialDate = () => {
    const storedDates = getStoredDates();
    if (storedDates.length > 0) {
      return storedDates[storedDates.length - 1]; 
    }
    return new Date().toLocaleDateString('pt-BR'); 
  };

  const uniqueCategories = getUniqueCategories();
  const initialDate = setInitialDate();

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetchPageData(pageNumber);
  };

  const fetchPageData = async (page) => {
    try {
      setIsLoading(true);
      const selectedDate = getStoredDates().pop(); // Pega a última data armazenada
      const data = await fetchClippingsByDate(clientId, selectedDate, selectedDate, page, itemsPerPage);
      
      setFilteredData(data.results);
      updateCategoryCounts(data.results);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to fetch clippings:', error);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    const renderEllipsis = (key) => (
      <span key={key} className="pagination-ellipsis">...</span>
    );

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3);
        pages.push(renderEllipsis('ellipsis-right'));
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1);
        pages.push(renderEllipsis('ellipsis-left'));
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push(renderEllipsis('ellipsis-left'));
        pages.push(currentPage - 1, currentPage, currentPage + 1);
        pages.push(renderEllipsis('ellipsis-right'));
        pages.push(totalPages);
      }
    }

    return pages.map((page, index) =>
      typeof page === 'number' ? (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ) : (
        page
      )
    );
  };

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
  };



  return (
    <div className={`App ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      <div className="menu">
        <Menu onToggleMenu={handleToggleMenu} />
      </div>
      <div className="navbar">
        <NavBar />
      </div>

      {isLoading && (
          <div className="loading-overlay active">
            <ReactLoading type={'spin'} color={'#121926'} height={80} width={80} />
          </div>
        )}

      <div className="main">
        <div className="welcome-message">
          <h2>CLIPPING DO DIA!</h2>
            <div className="user-info">
              <p>Bem-vindo(a), {clientName}! As notícias mais recentes já estão prontas para você.</p>
            </div>
        </div>
        
        <div className="filtercategory">
          <FiltersCategory onFilterChange={handleFilterChange} />
        </div>

        {showAdvancedFilters && (
          <div className="advanced-filters">
            <div className="datefilter">
              <div className="date">
                <CustomDate onDateSelected={handleDateSelected} initialDate={initialDate} />
              </div>
              <div className="filters">
                <Filters
                  onCategoryFilterChange={handleCategoryFilterChange}
                  onSortChange={handleSortChange}
                  showCategoryDropdown={showCategoryDropdown}
                  categories={uniqueCategories}
                  onEvaluateFilterChange={handleEvaluateFilterChange}
                />
              </div>
            </div>

            {filterType !== 'Assuntos' && (
              <div className="filtersearch">
                <FilterSearch onSourceNameSelected={handleSourceNameSearch} />
              </div>
            )}
          </div>
        )}

        <div className="pagination-container">
          <div className="pagination-info">
            {`Apresentando ${filteredData.length} de ${totalclippings} ${totalclippings === 1 ? 'Notícia' : 'Notícias'}`}
          </div>
          <button
            className={`advanced-search-button ${showAdvancedFilters ? 'advanced-search-button-close' : 'advanced-search-button-open'}`}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          >
            {showAdvancedFilters} {showAdvancedFilters ? 'Fechar' : 'Filtro Avançado'}
          </button>
        </div>

        {filterType === 'Assuntos' && (
          <div className="category-cards">
          {Object.keys(categoryCounts).map((category, index) => (
            <div key={`card-${index}`} className="category-card">
              <h3>{category} ({categoryCounts[category].count})</h3>
              <hr className='hr'/>
              {Object.keys(categoryCounts[category].types).map((type, i) => (
                <CardFiltrosAssuntos
                  key={`category-button-${category}-${type}`}
                  category={type}
                  count={categoryCounts[category].types[type]}
                  onClick={() => handleCategoryFilter(category, type)}
                />
              ))}
            </div>
          ))}
          </div>
        )}

        <div className="card-list">
          <ClippingList filteredData={filteredData} />
        </div>

        {filteredData.length > 0 && (
          <div className="pagination-wrapper-tela-noticias">
            <button
              className="pagination-button2"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              Primeira Página
            </button>
            <button
              className="pagination-button2"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            {renderPageNumbers()}
            <button
              className="pagination-button2"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Próxima
            </button>
            <button
              className="pagination-button2"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              Última Página
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TelaNoticias;
