import React, { useState, useEffect, useRef } from 'react';
import './TelaDadosCadastrais.css';
import InputMask from 'react-input-mask';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import { fetchImage } from '../../services/api/fetchUser';
import { updateUser } from '../../services/api/fetchUser';
import { fetchUser } from '../../services/api/fetchUser';
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';

function TelaDadosCadastrais() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    photo: '' // Inclua a URL da foto no estado
  });
  const [originalUserData, setOriginalUserData] = useState(null);
  const [errors, setErrors] = useState({}); // Estado para armazenar os erros
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [photoSuccessMessage, setPhotoSuccessMessage] = useState(''); // Nova mensagem de sucesso para a foto
  const [photoErrorMessage, setPhotoErrorMessage] = useState(''); // Nova mensagem de erro para a foto
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const { clientId } = useParams();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoadingProgress(30);
        const user = await fetchUser(clientId); // Passa o clientId para a função de busca de usuário
        setUserData(user);
        setOriginalUserData(user); // Mantenha uma cópia dos dados originais
        setLoadingProgress(100);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoadingProgress(100);
      } finally {
        setIsLoading(false);
      }
    };

    getUserData();
  }, [clientId]); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const getModifiedFields = () => {
    if (!originalUserData) return {};

    const modifiedFields = {};
    Object.keys(userData).forEach(key => {
      if (userData[key] !== originalUserData[key]) {
        modifiedFields[key] = userData[key];
      }
    });
    return modifiedFields;
  };

  const handleSave = async () => {
    const modifiedFields = getModifiedFields();
    console.log('Dados a serem enviados:', modifiedFields); // Adiciona um log para verificar os dados

    try {
      await updateUser(modifiedFields);
      setSuccessMessage('Dados alterados com sucesso!');
      setErrorMessage(''); // Limpar mensagem de erro
      setErrors({}); // Limpa os erros após o sucesso
      setTimeout(() => setSuccessMessage(''), 5000); // Limpar mensagem após 5 segundos
    } catch (error) {
      console.error('Erro ao salvar os dados do usuário:', error);
      console.error('Resposta do erro:', error.response?.data); // Adiciona um log para verificar a resposta do erro
      setErrorMessage('Erro ao salvar os dados do usuário.');
      setSuccessMessage(''); // Limpar mensagem de sucesso
      setErrors(error.response?.data || {}); // Define os erros
      setTimeout(() => setErrorMessage(''), 5000); // Limpar mensagem após 5 segundos
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    console.log('Foto selecionada:', file);
    
    try {
      const response = await fetchImage(file);
      setUserData({
        ...userData,
        photo: response.photoUrl // Ajuste de acordo com a resposta da API
      });
      setPhotoSuccessMessage('Foto alterada com sucesso!');
      setPhotoErrorMessage(''); // Limpar mensagem de erro
      setTimeout(() => setPhotoSuccessMessage(''), 5000); // Limpar mensagem após 5 segundos
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error);
      setPhotoErrorMessage('Erro ao enviar a imagem.');
      setPhotoSuccessMessage(''); // Limpar mensagem de sucesso
      setTimeout(() => setPhotoErrorMessage(''), 5000); // Limpar mensagem após 5 segundos
    }
  };

  const handlePhotoClick = () => {
    fileInputRef.current.click();
  };

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
  };

  return (
    <div className={`container-dados_cadastrais ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      <LoadingBar
        color='#004080'  // Cor azul escuro
        progress={loadingProgress}
        onLoaderFinished={() => setLoadingProgress(0)}
      />
      {!isLoading ? (
        <>
          <div className="menu-dados-cadastrais">
            <Menu onToggleMenu={handleToggleMenu} />
          </div>
          <div className="navbar-dados-cadastrais">
            <NavBar />
          </div>
          <div className="main-dados-cadastrais">
            <div className='dados-pesssoais'>
              <div className="div-2-dados-cadastrais">
                <div className="column-dados-cadastrais">
                  <img
                    loading="lazy"
                    src={userData.photo ? userData.photo : require('../../assets/nxt_logo.jpg')}
                    className="img-dados-cadastrais"
                    alt="User"
                  />
                </div>
                <div className="column-2-dados-cadastrais">
                  <div className="div-3-dados-cadastrais">
                    <div className="div-4-dados-cadastrais">{userData.name}</div>
                    <div className="div-5-dados-cadastrais" onClick={handlePhotoClick}>
                      Trocar Foto
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {photoSuccessMessage && <div className="success-message">{photoSuccessMessage}</div>}
            {photoErrorMessage && <div className="error-message">{photoErrorMessage}</div>}

            <div className='input-dados-cadastrais'>
              <div className="date-picker-dados-cadastrais">
                <div className="date-picker-label-dados-cadastrais">Nome</div>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  className="custom-select-dados-cadastrais"
                  placeholder="Digite o texto aqui"
                />
                {errors.name && <div className="error-message">{errors.name[0]}</div>} {/* Exibe o erro de nome */}
              </div>

              <div className="date-picker-dados-cadastrais">
                <div className="date-picker-label-dados-cadastrais">Email</div>
                <input
                  type="text"
                  name="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  className="custom-select-dados-cadastrais"
                  placeholder="Digite o texto aqui"
                />
                {errors.email && <div className="error-message">{errors.email[0]}</div>} {/* Exibe o erro de email */}
              </div>

              <div className="date-picker-dados-cadastrais">
                <div className="date-picker-label-dados-cadastrais">Telefone</div>
                <InputMask
                  mask="(99) 99999-9999"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  className="custom-select-dados-cadastrais"
                  placeholder="Digite o telefone aqui"
                />
                {errors.phone && <div className="error-message">{errors.phone[0]}</div>} {/* Exibe o erro de telefone */}
              </div>
            </div>

            <div className='Buttons-dados-cadastrais'>
              <a href={'/'+ clientId + '/telaNoticias'} className='voltar-dados-cadastrais'>
                Voltar
              </a>

              <button className='salvar-dados-cadastrais' onClick={handleSave}>
                Salvar
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="loading-container">
          <div className="loading-bar">
            <LoadingBar
              color='#004080'  // Cor azul escuro
              progress={loadingProgress}
              onLoaderFinished={() => setLoadingProgress(0)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TelaDadosCadastrais;
