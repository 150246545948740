import React, { useState, useEffect } from "react";
import "./FilterSearch.css";

function FilterSearch({ onCategorySelected, onSourceNameSelected }) {
  const [categoria, setCategoria] = useState(""); // Estado para armazenar a categoria digitada
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState(() => {
    const storedCategories = localStorage.getItem("categoriasSelecionadas");
    return storedCategories ? JSON.parse(storedCategories) : [];
  }); // Estado para armazenar as categorias selecionadas

  useEffect(() => {
    // Limpa categorias selecionadas ao recarregar a página
    const handlePageReload = () => {
      localStorage.removeItem("categoriasSelecionadas");
    };

    window.addEventListener("beforeunload", handlePageReload);

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("categoriasSelecionadas", JSON.stringify(categoriasSelecionadas));
  }, [categoriasSelecionadas]);

  const handleInputChange = (event) => {
    setCategoria(event.target.value); // Atualiza o estado com o texto digitado
  };

  const handleSearch = () => {
    if (categoria.trim() !== "") {
      const newCategory = categoria.trim();
      const updatedCategories = [...categoriasSelecionadas, newCategory];
      setCategoriasSelecionadas(updatedCategories); // Adiciona a categoria às categorias selecionadas
      
      // Chama a função de callback com as categorias selecionadas
      if (onCategorySelected) {
        onCategorySelected(updatedCategories.join(','));
      }

      if (onSourceNameSelected) {
        onSourceNameSelected(updatedCategories);
      }
      
      setCategoria(""); // Limpa o input após adicionar a categoria
    }
  };

  const handleRemoveCategory = (removedCategory) => {
    const updatedCategories = categoriasSelecionadas.filter(
      (cat) => cat !== removedCategory
    );
    setCategoriasSelecionadas(updatedCategories);
    
    // Atualiza o filtro com as categorias restantes
    if (onCategorySelected) {
      onCategorySelected(updatedCategories.join(','));
    }

    if (onSourceNameSelected) {
      onSourceNameSelected(updatedCategories);
    }
  };

  return (
    <div className="filter-news-container">
      <div className="filter-news-wrapper">
        <input
          type="text"
          value={categoria}
          onChange={handleInputChange}
          className="filter-news-input"
          placeholder="Digite o Veículo"
        />
        <button onClick={handleSearch} className="filter-news-button">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fdc9e9912101de3ad61bde5e8aee586e240483528e8d8b8fc735b79acb147671?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
            alt="Search"
            className="filter-news-icon"
          />
        </button>
      </div>
      <hr className="divider-line" />
      <div className="selected-categories">
        {categoriasSelecionadas.map((cat, index) => (
          <div key={index} className="selected-category">
            {cat}
            <span
              onClick={() => handleRemoveCategory(cat)}
              className="remove-button"
            >
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ad351e4c7a0682391e980d69ebfc6bfce384062db76a83b02eb0f781588ea138?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                alt="Remover"
                className="close-icon"
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterSearch;
