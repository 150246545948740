import React from 'react';
import './ClippingList.css';
import Card from '../Card/Card';
import defaultImage from '../../assets/icone-card-tv.jpg'; 
import tvImage from '../../assets/icone-card-tv.jpg'; 
import radioImage from '../../assets/icone-crad-radio.jpg'; 
import printImage from '../../assets/icone-card-impresso.jpg'; 
import siteImage from '../../assets/icone-card-site.jpg';
import impressoImage from '../../assets/icone-card-impresso.jpg'
import { useParams, useLocation } from 'react-router-dom';

const ClippingList = ({ filteredData }) => {
  const getImageUrl = (type) => {
    if (type === 'tv') {
      return tvImage;
    } else if (type === 'radio') {
      return radioImage;
    } else if (type === 'print') {
      return printImage;
    } else if (type === 'site') {
      return siteImage;
    } else if (type === 'impresso') {
      return impressoImage;
    } else {
      return defaultImage;
    }
  };

  const { clientId } = useParams();
  const location = useLocation();

  return (
    <div className="clipping-list">
      {filteredData.map((clipping) => (
        <Card
          key={clipping.id}
          clippingId={clipping.id}
          imageUrl={getImageUrl(clipping.source_detail.source_type)}
          title={clipping.title}
          type={clipping.source_detail.source_type}
          publicationDate={clipping.publish_date}
          publicationClipping={clipping.publish_date}
          sourceName={clipping.source_detail.name}
          category={clipping.categories} 
          text={clipping.content}
          city={clipping.source_detail.city}
          state={clipping.source_detail.city}
          source={clipping.source_detail.name}
          hasEvaluate={clipping.has_evaluation}
          clippings={filteredData} // Passa a lista completa de clippings
        />
      ))}
    </div>
  );
};

export default ClippingList;
