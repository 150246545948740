import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Filters.css';

function Filters({ onCategoryFilterChange, onSortChange, showCategoryDropdown, categories, onEvaluateFilterChange }) {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    const storedCategory = localStorage.getItem('selectedCategory');
    return storedCategory ? JSON.parse(storedCategory) : { value: '', label: 'Todas' };
  });

  const [selectedSort, setSelectedSort] = useState(() => {
    const storedSort = localStorage.getItem('selectedSort');
    return storedSort ? JSON.parse(storedSort) : { value: 'opcaoA', label: 'Mais Recente' };
  });

  const [selectedEvaluate, setSelectedEvaluate] = useState(() => {
    const storedEvaluate = localStorage.getItem('selectedEvaluate');
    return storedEvaluate ? JSON.parse(storedEvaluate) : { value: 'todos', label: 'Todos' };
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '245px',
      padding: '10px',
      fontSize: '14px',
      borderColor: state.isFocused ? '#ccc' : '#ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
      transition: 'border-color 0.3s, box-shadow 0.3s',
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      color: '#333',
      padding: '10px',
      '&:active': {
        backgroundColor: '#eee',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  useEffect(() => {
    // Limpa o item de categoria do localStorage ao recarregar a página
    const handlePageReload = () => {
      localStorage.removeItem('selectedCategory');
    };

    window.addEventListener('beforeunload', handlePageReload);

    return () => {
      window.removeEventListener('beforeunload', handlePageReload);
    };
  }, []);

  useEffect(() => {
    const storedCategory = localStorage.getItem('selectedCategory');
    if (storedCategory) {
      const parsedCategory = JSON.parse(storedCategory);
      if (parsedCategory && parsedCategory.value !== undefined) {
        setSelectedCategory(parsedCategory);
        onCategoryFilterChange(parsedCategory.value);
      }
    }
  }, [onCategoryFilterChange]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.value !== '') {
      onCategoryFilterChange(selectedCategory.value);
    } else {
      onCategoryFilterChange('');
    }
  }, [selectedCategory, onCategoryFilterChange]);

  useEffect(() => {
    localStorage.setItem('selectedSort', JSON.stringify(selectedSort));
  }, [selectedSort]);

  useEffect(() => {
    localStorage.setItem('selectedEvaluate', JSON.stringify(selectedEvaluate));
  }, [selectedEvaluate]);

  const handleCategorySelect = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleSortSelect = (selectedOption) => {
    setSelectedSort(selectedOption);
    onSortChange(selectedOption.value);
  };

  const handleEvaluateSelect = (selectedOption) => {
    setSelectedEvaluate(selectedOption);
    onEvaluateFilterChange(selectedOption.value);
  };

  const categoryOptions = [{ value: '', label: 'Todas' }, ...categories.map(category => ({ value: category, label: category }))];
  const sortOptions = [
    { value: 'opcaoA', label: 'Mais Recente' },
    { value: 'opcaoB', label: 'Mais Antiga' },
  ];
  const evaluateOptions = [
    { value: 'todos', label: 'Todos' },
    { value: 'avaliado', label: 'Avaliados' },
    { value: 'nao-avaliado', label: 'Não Avaliados' },
  ];

  return (
    <div className="search-container-filters">
      {/* Dropdown para Categoria (renderizar apenas se showCategoryDropdown for verdadeiro) */}
      {showCategoryDropdown && (
        <div className="date-picker-filters">
          <div className="date-picker-label-filters">Categoria</div>
          <Select
            value={selectedCategory}
            onChange={handleCategorySelect}
            options={categoryOptions}
            styles={customStyles}
            placeholder="Todas"
          />
        </div>
      )}

      {/* Dropdown para Ordenação (sempre renderizado) */}
      <div className="date-picker-filters">
        <div className="date-picker-label-filters">Ordenação</div>
        <Select
          value={selectedSort}
          onChange={handleSortSelect}
          options={sortOptions}
          styles={customStyles}
          placeholder="Selecione a ordenação"
        />
      </div>

      <div className="date-picker-filters">
        <div className="date-picker-label-filters">Avaliação</div>
        <Select
          value={selectedEvaluate}
          onChange={handleEvaluateSelect}
          options={evaluateOptions}
          styles={customStyles}
          placeholder="Selecione a avaliação"
        />
      </div>
    </div>
  );
}

export default Filters;
