import { get } from './generic.api';
import format from 'date-fns/format';

export const fetchReportClear = async (clientId, startDate, endDate, categoryId, clear = false) => {
    try {
        const formattedStartDate = format(new Date(startDate), 'dd/MM/yyyy');
        const formattedEndDate = format(new Date(endDate), 'dd/MM/yyyy');

        const params = {
            client: clientId,
            date_start: formattedStartDate,
            date_end: formattedEndDate,
        };
        
        if (categoryId) params.category = categoryId;
        if (clear) params.clear = true;

        const response = await get('/clipping/report/', params);
        return response;
    } catch (error) {
        console.error('Erro ao buscar relatório:', error.response);
        throw error;
    }
};