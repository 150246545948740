import React from 'react';
import './BarChart.css';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 export function BarChart({dataPoints}) {
  const options = {
    width: 500,
    height: 500,
    backgroundColor: "transparent",
    animationEnabled: false,
    axisX: {
      interval: 1,
      labelMaxWidth:150,
			labelWrap: false, 
      labelFontSize:9,
    },
    axisY: {
      labelFontSize:9,
    },
    axisYType: "secondary",
    data: [{
      type: "bar",
      dataPoints: dataPoints.sort((a, b) => a.y - b.y)
    }]
  }

    return (
     <div>
<CanvasJSChart options = {options}
          /* onRef = {ref => this.chart = ref} */
        />
     </div>
    );
  }
  
  export default BarChart;