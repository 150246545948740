import React from 'react';

import './DonutChart.css';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 export function DonutChart({dataPoints, innerRadius, width, height, fontSize, hideLabel}) {

    const options = {
        width: width ? width : 500,
        height: height ? height : 400,
        backgroundColor: "transparent",
        animationEnabled: false,
        navigator: {
          animationEnabled: false,
        },
        toolTip:{
          enabled: false,
          animationEnabled: true,
        },
        title: {
          horizontalAlign: "center"
                },
        data: [{
          type: "doughnut",
          startAngle: 270,
          innerRadius: innerRadius ? innerRadius : 60,
          indexLabelFontSize: fontSize ? fontSize : 12,
          indexLabel: hideLabel ? "" : "{label} - #percent%",
          dataPoints: dataPoints
        }],
        rendered: function () {
          Promise.resolve();
        }

        
      }

  
    return (
<CanvasJSChart options = {options} />
    );
  }
  
  export default DonutChart;