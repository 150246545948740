import React, { createContext, useContext, useState } from 'react';

// Cria o contexto
const ClippingsContext = createContext();

// Cria um provider para o contexto
export const ClippingsProvider = ({ children }) => {
  const [clippings, setClippings] = useState([]);
  const [initialFilteredData, setInitialFilteredData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState('todos');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [recentFirst, setRecentFirst] = useState(true);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(true);
  const [categoryCounts, setCategoryCounts] = useState({});
  const [selectedDropdownCategory, setSelectedDropdownCategory] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <ClippingsContext.Provider value={{
      clippings, setClippings,
      initialFilteredData, setInitialFilteredData,
      filteredData, setFilteredData,
      filterType, setFilterType,
      selectedCategories, setSelectedCategories,
      recentFirst, setRecentFirst,
      showCategoryDropdown, setShowCategoryDropdown,
      categoryCounts, setCategoryCounts,
      selectedDropdownCategory, setSelectedDropdownCategory,
      startDate, setStartDate,
      endDate, setEndDate
    }}>
      {children}
    </ClippingsContext.Provider>
  );
};

// Hook para usar o contexto
export const useClippings = () => useContext(ClippingsContext);
