// ExportModal.js
import React from 'react';
import { FaFileExcel, FaFilePdf, FaTimes } from 'react-icons/fa';
import './ModalExport.css';

export function ExportModal({ onClose, onExportExcel, onExportPDF }) {
  const handleExportExcel = () => {
    onExportExcel();
    onClose();
  };

  const handleExportPDF = () => {
    onExportPDF();
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Exportar</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-body">
          <button className="export-button" onClick={handleExportExcel}>
            <FaFileExcel className="icon" />
            <span>Exportar Excel</span>
          </button>
          <button className="export-button" onClick={handleExportPDF}>
            <FaFilePdf className="icon" />
            <span>Exportar PDF</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExportModal;
