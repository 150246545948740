import { post, del, get } from './generic.api';

export const fetchClientData = async (clientId) => {
    try {
      const response = await get(`/client/${clientId}`);
  
      // Verificar se o cliente com o ID específico está presente na resposta
      const client = response;
  
      if (!client) {
        throw new Error('Client not found');
      }
  
      return client;
    } catch (error) {
      console.error('Erro ao buscar dados do cliente:', error.response || error.message);
      throw error;
    }
  };

  