import { post, del, get } from './generic.api';

export const fetchAuthors = async (editorialId) => {
    try {
      const response = await get('/authors/', {
            source: editorialId 
      });
      return response.results; // Corrige para retornar a propriedade results
    } catch (error) {
      console.error('Erro ao carregar autores:', error.response);
      throw error;
    }
  };
  