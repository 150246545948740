import { post, del, get } from './generic.api';

export const fetchGroupers = async (clientId) => {
    try {
      const response = await get('/groupers/', {
            client: clientId 
      });
      return response.results;
    } catch (error) {
      console.error('Erro ao buscar groupers:', error.response);
      throw error;
    }
  };

  export const fetchGroupersData = async (clippingId) => {
    try {
      const response = await get('/groupers-data/', {
            clipping: clippingId 
      });
      return response.results;
    } catch (error) {
      console.error('Erro ao buscar groupers-data:', error.response);
      throw error;
    }
  };

  export const sendEvaluationData = async (filterData) => {
    try {
      const response = await post('/groupers-data/mass_evaluation/', filterData);
      return response;
    } catch (error) {
      console.error('Erro ao enviar dados de avaliação:', error.response);
      throw error;
    }
  };