import React, { useState, useEffect } from "react";
import "./FilterCategory.css";

function FilterCategory({ onFilterChange, showAssuntos = true }) {
  const [selectedFilter, setSelectedFilter] = useState(() => {
    const storedFilter = localStorage.getItem("selectedFilter");
    return storedFilter ? storedFilter : "todos";
  });

  useEffect(() => {
    // Limpar categorias selecionadas ao recarregar a tela
    const handlePageReload = () => {
      localStorage.removeItem("selectedFilter");
      setSelectedFilter("todos");
      onFilterChange("todos");
    };

    window.addEventListener("beforeunload", handlePageReload);

    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, [onFilterChange]);

  useEffect(() => {
    const storedFilter = localStorage.getItem("selectedFilter");
    if (!storedFilter) {
      setSelectedFilter("todos");
      onFilterChange("todos");
      localStorage.setItem("selectedFilter", "todos");
    }
  }, [onFilterChange]);

  useEffect(() => {
    localStorage.setItem("selectedFilter", selectedFilter);
  }, [selectedFilter]);

  const handleFilterClick = (filter) => {
    const normalizedFilter = filter === "Assuntos" ? filter : filter.toLowerCase();
    setSelectedFilter(normalizedFilter);
    onFilterChange(normalizedFilter);
  };

  const filters = ["Todos", "Site", "Blog", "Impresso", "Radio", "TV"];
  if (showAssuntos) {
    filters.splice(1, 0, "Assuntos");
  }


  return (
    <nav className="filter-container">
      {filters.map((filter) => (
        <button
          key={filter}
          className={selectedFilter === (filter === "Assuntos" ? filter : filter.toLowerCase()) ? "active" : ""}
          onClick={() => handleFilterClick(filter)}
        >
          {filter}
        </button>
      ))}
    </nav>
  );
}

export default FilterCategory;
