import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Date.css";

function CustomDate({ onDateSelected, onAnotherDateSelected }) {
  const [selectedDate, setSelectedDate] = useState(null);

  // useEffect para definir a data inicial ao carregar o componente
  useEffect(() => {
    const lastSelectedDate = sessionStorage.getItem('lastSelectedDate');
    const pageReloaded = sessionStorage.getItem('pageReloaded');
    
    if (pageReloaded) {
      sessionStorage.removeItem('lastSelectedDate');
      sessionStorage.removeItem('pageReloaded');
      const now = new Date();
      setSelectedDate(now);
    } else if (lastSelectedDate) {
      setSelectedDate(new Date(lastSelectedDate));
    } else {
      const now = new Date();
      setSelectedDate(now);
    }

    // Marcar a página como recarregada
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('pageReloaded', 'true');
    });

    // Limpar o listener ao desmontar o componente
    return () => {
      window.removeEventListener('beforeunload', () => {
        sessionStorage.setItem('pageReloaded', 'true');
      });
    };
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formatDateString = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSearch = () => {
    // Armazenar a data selecionada no sessionStorage
    if (selectedDate) {
      sessionStorage.setItem('lastSelectedDate', selectedDate.toISOString());
    }
    
    // Chama a função de callback com a data selecionada
    if (onDateSelected) {
      onDateSelected(formatDateString(selectedDate));
    }
    if (onAnotherDateSelected) {
      onAnotherDateSelected(formatDateString(selectedDate));
    }
  };

  return (
    <div className="search-container">
      <div className="date-picker">
        <div className="date-picker-label">Data</div>
        <div className="date-picker-header">
          <div className="date-picker-content">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              customInput={
                <input
                  type="text"
                  className="selected-date-input"
                  value={selectedDate ? formatDateString(selectedDate) : ""}
                  readOnly
                />
              }
            />
          </div>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
            alt=""
            className="calendar-icon"
            onClick={() => document.querySelector(".react-datepicker__input-container input").focus()}
          />
        </div>
      </div>
      <button className="search-button" onClick={handleSearch}>
        Buscar
      </button>
    </div>
  );
}

export default CustomDate;
