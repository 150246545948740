import React, { useState } from 'react';
import './TelaDadosUsuario.css';
import NavBar from '../../components/navbar/NavBar';
import Menu from '../../components/menu/Menu';
import TelaDadosCadastrais from '../TelaDadosCadastrais/TelaDadosCadastrais';
import TelaRedefinicaoSenha from '../TelaRedefinicaoSenha/TelaRedefinicaoSenha';
import { useParams } from 'react-router-dom';

function TelaDadosUsuario() {
  const [activeTab, setActiveTab] = useState('tab1');
  const { clientId } = useParams();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const handleVoltar = () => {
    setActiveTab('tab1');
  };

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
};

  return (
    <div className={`container-dados-usuario ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      <div className="menu-dados-usuario">
        <Menu onToggleMenu={handleToggleMenu} />
      </div>
      <div className="navbar-dados-usuario">
        <NavBar />
      </div>

      <div className="main-dados-usuario">
        <div className="tabs-container-usuario">
          <div className="tabs-usuario">
            <button
              className={`tab-usuario ${activeTab === 'tab1' ? 'active' : ''}`}
              onClick={() => setActiveTab('tab1')}
            >
              Dados cadastrais
            </button>
            <button
              className={`tab2-usuario ${activeTab === 'tab2' ? 'active' : ''}`}
              onClick={() => setActiveTab('tab2')}
            >
              Senha
            </button>
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 'tab1' && <TelaDadosCadastrais />}
          {activeTab === 'tab2' && <TelaRedefinicaoSenha onVoltar={handleVoltar} />}
        </div>
      </div>
    </div>
  );
}

export default TelaDadosUsuario;
