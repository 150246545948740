import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CadastrarNoticia.css';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import { fetchStates } from '../../services/api/fetchLocation';
import { createClipping } from '../../services/api/fetchClipping';
import { fetchPresenters } from '../../services/api/fetchPresenters';
import { fetchAuthors } from '../../services/api/fetchAuthors';
import { fetchPrograms } from '../../services/api/fetchPrograms';
import { fetchEditorials } from '../../services/api/fetchEditorials';
import { fetchVehicles } from '../../services/api/fetchSources';
import { fetchCategories } from '../../services/api/fetchCategories';
import axios from 'axios';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';

function CadastrarNoticia() {
    const [veiculo, setVeiculo] = useState(null);
    const [editoria, setEditoria] = useState(null);
    const [programa, setPrograma] = useState(null);
    const [autor, setAutor] = useState([]);
    const [apresentador, setApresentador] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [resumo, setResumo] = useState('');
    const [conteudo, setConteudo] = useState('');
    const [dataPublicacao, setDataPublicacao] = useState(new Date());
    const [horaPublicacao, setHoraPublicacao] = useState(new Date());
    const [categorias, setCategorias] = useState([]);
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0); // Novo estado
    const { clientId } = useParams();
    const [categoriasOptions, setCategoriasOptions] = useState([]);
    const [error, setError] = useState(null); // Novo estado para mensagem de erro
    const [success, setSuccess] = useState(null); // Novo estado para mensagem de sucesso
    const [mediaError, setMediaError] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const MAX_FILE_SIZE_MB = 500;
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [occupiedSpace, setOccupiedSpace] = useState('');
    const [mediaContentType, setMediaContentType] = useState(null);
    const [sourceType, setSourceType] = useState(null); // Novo estado para sourceType

    const sourceTypeOptions = [
      { value: 'tv', label: 'TV' },
      { value: 'radio', label: 'Rádio' },
      { value: 'blog', label: 'Blog' },
      { value: 'site', label: 'Site' }
    ];

    const mediaContentTypeOptions = [
      { value: 'MATÉRIA', label: 'MATÉRIA' },
      { value: 'ARTIGO', label: 'ARTIGO' },
      { value: 'ANÚNCIO/PUBLICIDADE', label: 'ANÚNCIO/PUBLICIDADE' },
      { value: 'AO VIVO E CHAMADA', label: 'AO VIVO E CHAMADA' },
      { value: 'CARTA/EMAIL', label: 'CARTA/EMAIL' },
      { value: 'CHAMADA DE CAPA', label: 'CHAMADA DE CAPA' },
      { value: 'CHARGE', label: 'CHARGE' },
      { value: 'CINEMA', label: 'CINEMA' },
      { value: 'COLUNA', label: 'COLUNA' },
      { value: 'CRÔNICA', label: 'CRÔNICA' },
      { value: 'CURTAS', label: 'CURTAS' },
      { value: 'DEBATE', label: 'DEBATE' },
      { value: 'EDITAL', label: 'EDITAL' },
      { value: 'EDITORIAL', label: 'EDITORIAL' },
      { value: 'ENTREVISTA', label: 'ENTREVISTA' },
      { value: 'ESCALADA', label: 'ESCALADA' },
      { value: 'FEED', label: 'FEED' },
      { value: 'IGTV', label: 'IGTV' },
      { value: 'INSERÇÃO ELEITORAL', label: 'INSERÇÃO ELEITORAL' },
      { value: 'MATÉRIA COM CHAMADA', label: 'MATÉRIA COM CHAMADA' },
      { value: 'NOTA', label: 'NOTA' },
      { value: 'PROGRAMA ELEITORAL', label: 'PROGRAMA ELEITORAL' },
      { value: 'REPORTAGEM', label: 'REPORTAGEM' },
      { value: 'STORIES', label: 'STORIES' }
    ];

    const [veiculosOptions, setVeiculosOptions] = useState([]);
    const [editoriasOptions, setEditoriasOptions] = useState([]);
    const [programasOptions, setProgramasOptions] = useState([]);
    const [autoresOptions, setAutoresOptions] = useState([]);
    const [apresentadoresOptions, setApresentadoresOptions] = useState([]);
    const fileInputRef = useRef(null); // Adicionar a referência
    const [estado, setEstado] = useState(null);
    const [estadosOptions, setEstadosOptions] = useState([]);
    const [isVehicleLoading, setIsVehicleLoading] = useState(false);

    useEffect(() => {
    }, [mediaContentType]);

    useEffect(() => {
        const loadCategories = async () => {
          try {
            const categories = await fetchCategories(clientId);
            const filteredCategories = filterHighestIdOptions(categories.map(cat => ({ value: cat.id, label: cat.name })));
            setCategoriasOptions(filteredCategories);
            console.log('Dados de categorias recebidos:', categories);
          } catch (error) {
            console.error('Erro ao carregar categorias:', error);
          }
        };
        loadCategories();
        setEstado(null);
      }, [clientId]);

    useEffect(() => {
      const loadData = async () => {
        if (veiculo) {
          try {
            const editorials = await fetchEditorials(veiculo.value);
            setEditoriasOptions(editorials.map(ed => ({ value: ed.id, label: ed.name })));

            const programs = await fetchPrograms(veiculo.value);
            const filteredPrograms = filterHighestIdOptions(programs.map(pr => ({ value: pr.id, label: pr.name })));
            setProgramasOptions(filteredPrograms);

            const presenters = await fetchPresenters(veiculo.value);
            const filteredPresenters = filterHighestIdOptions(presenters.map(ap => ({ value: ap.id, label: ap.name })));
            setApresentadoresOptions(filteredPresenters);
          } catch (error) {
            console.error('Erro ao carregar dados:', error);
          }
        }
      };
      loadData();
    }, [veiculo]);

    useEffect(() => {
      const loadAuthors = async () => {
        if (editoria) {
          try {
            const authors = await fetchAuthors(editoria.value);
            const filteredAuthors = filterHighestIdOptions(authors.map(au => ({ value: au.id, label: au.name })));
            setAutoresOptions(filteredAuthors);
          } catch (error) {
            console.error('Erro ao carregar autores:', error);
          }
        }
      };
      loadAuthors();
    }, [editoria]);

    useEffect(() => {
      const loadPresenters = async () => {
        if (programa) {
          try {
            const presenters = await fetchPresenters(programa.value);
            setApresentadoresOptions(presenters.map(ap => ({ value: ap.id, label: ap.name })));
          } catch (error) {
            console.error('Erro ao carregar apresentadores:', error);
          }
        }
      };
      loadPresenters();
    }, [programa]);

    useEffect(() => {
      const loadStates = async () => {
        const estados = await fetchStates();
        setEstadosOptions(estados.map(state => ({ value: state.id, label: state.name })));
      };
      loadStates();
    }, []);

    useEffect(() => {
      setEstado(null);
    }, []);

    const resetForm = () => {
        setVeiculo(null);
        setEditoria(null);
        setPrograma(null);
        setAutor([]);
        setApresentador([]);
        setTitulo('');
        setResumo('');
        setConteudo('');
        setDataPublicacao(new Date());
        setHoraPublicacao(new Date());
        setCategorias([]);
        setFiles([]);
        setPreviews([]);
        setCurrentPreviewIndex(0);
        setSourceType(null);
        setEstado(null);
      };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        width: '250px',
        padding: '5px',
        fontSize: '14px',
        borderColor: state.isFocused ? '#ccc' : '#ccc',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#333',
        outline: 'none',
        boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        marginTop: '10px',
        '&:hover': {
          borderColor: '#ccc',
        },
        '@media (max-width: 1860px)': {
            width: '200px', // Ajuste a largura para telas menores que 1500px
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', // Corrigido "alignItems"
          },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#eee' : '#fff',
        color: '#333',
        padding: '10px',
        '&:active': {
          backgroundColor: '#eee',
        },
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 2,
      }),
    };

    const customdropdown = {
      control: (provided, state) => ({
        ...provided,
        width: '775px',
        padding: '5px',
        fontSize: '14px',
        borderColor: state.isFocused ? '#ccc' : '#ccc',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: '#333',
        outline: 'none',
        boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
        transition: 'border-color 0.3s, box-shadow 0.3s',
        marginTop: '10px',
        '&:hover': {
          borderColor: '#ccc',
        },
        '@media (max-width: 1860px)': {
            width: '540px', // Ajuste a largura para telas menores que 1500px
          },
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#eee' : '#fff',
        color: '#333',
        padding: '10px',
        '&:active': {
          backgroundColor: '#eee',
        },
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 2,
      }),
    };

    const handleVehicleInputChange = async (inputValue) => {
      setInputValue(inputValue);
      if (inputValue.length >= 3 && sourceType && estado) {
        setIsVehicleLoading(true);
        try {
          const vehicles = await fetchVehicles(inputValue, sourceType.value, estado.value, 2000);
          setVeiculosOptions(vehicles.map(ve => ({ value: ve.id, label: ve.name })));
        } catch (error) {
          console.error('Erro ao buscar veículos:', error);
        } finally {
          setIsVehicleLoading(false);
        }
      } else {
        setVeiculosOptions([]);
      }
    };
    

    const handleFileChange = (event) => {
      const filesArray = Array.from(event.target.files);
    
      let invalidFiles = [];
    
      // Validação do tamanho do arquivo
      invalidFiles = filesArray.filter(file => file.size > MAX_FILE_SIZE_MB * 1024 * 1024);
      if (invalidFiles.length > 0) {
        setMediaError('Tamanho máximo de arquivo 500 MB. Por favor, tente arquivos menores.');
        setTimeout(() => {
          setMediaError(null);
        }, 5000);
        return;
      }
    
      setFiles(filesArray);
      const previewUrls = filesArray.map(file => URL.createObjectURL(file));
      setPreviews(previewUrls);
    };

    const handleNextPreview = () => {
      setCurrentPreviewIndex((prevIndex) => (prevIndex + 1) % previews.length);
    };

    const handlePrevPreview = () => {
      setCurrentPreviewIndex((prevIndex) => (prevIndex - 1 + previews.length) % previews.length);
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      
      if (!veiculo || !titulo || !conteudo || categorias.length === 0 || !clientId || !dataPublicacao) {
        setError('Por favor, preencha todos os campos obrigatórios: Veículo, título, conteúdo, categorias e data publicação.');
        setTimeout(() => {
            setError(null);
          }, 5000);
        return;
      }

      const handleVehicleInputChange = async (inputValue) => {
        setInputValue(inputValue);
        if (inputValue.length >= 3 && sourceType && estado) {
          setIsLoading(true);
          try {
            const vehicles = await fetchVehicles(inputValue, sourceType.value, estado.value);
            setVeiculosOptions(vehicles.map(ve => ({ value: ve.id, label: ve.name })));
          } catch (error) {
            console.error('Erro ao buscar veículos:', error);
          } finally {
            setIsLoading(false);
          }
        } else {
          setVeiculosOptions([]);
        }
      };

      const formData = new FormData();
      formData.append('client', clientId);
      formData.append('source', veiculo?.value);
      formData.append('editorial', editoria ? editoria.value : '');
      formData.append('program', programa ? programa.value : '');
      formData.append('authors', JSON.stringify(autor.map(a => a.value)));
      formData.append('presenters', JSON.stringify(apresentador.map(p => p.value)));
      formData.append('title', titulo);
      formData.append('resume', resumo);
      formData.append('content', conteudo);
      formData.append('publish_date', `${dataPublicacao.toISOString().split('T')[0]}T${horaPublicacao.toTimeString().split(' ')[0]}`);
      formData.append('categories', JSON.stringify(categorias.map(c => c.value)));
      formData.append('occupied_space', occupiedSpace);
      formData.append('media_content_type', mediaContentType ? mediaContentType.value : '');
      files.forEach((file) => {
        formData.append(`medias`, file);
      });

      try {
        setIsLoading(true);
        const response = await createClipping(formData);
        console.log('Dados enviados para a API:', formData);
        console.log('Resposta da API:', response);
        setSuccess('Notícia cadastrada com sucesso!');
        setError(null); // Limpar mensagem de erro
            // Limpar mensagem de sucesso após 5 segundos
        setTimeout(() => {
        setSuccess(null);
      }, 5000);

      resetForm(); // Limpar o formulário após o envio bem-sucedido
      } catch (error) {
        console.error('Erro ao enviar dados para a API:', error);
        setError('Erro ao cadastrar a notícia: ' + (error.response?.data?.message || error.message));
        setSuccess(null); // Limpar mensagem de sucesso
            // Limpar mensagem de sucesso após 5 segundos
        setTimeout(() => {
        setSuccess(null);
      }, 5000);
      } finally {
        setIsLoading(false);
      }
    };

    const clearFiles = () => {
      setFiles([]);
      setPreviews([]);
      setCurrentPreviewIndex(0);
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Redefinir o valor do input de arquivo
      }
    };

    // Função para filtrar opções duplicadas e manter apenas a de ID mais alto
    const filterHighestIdOptions = (options) => {
      const uniqueOptions = options.reduce((acc, option) => {
          const existingOption = acc.find(item => item.label === option.label);
          if (!existingOption || option.value > existingOption.value) {
              acc = acc.filter(item => item.label !== option.label);
              acc.push(option);
          }
          return acc;
      }, []);
      return uniqueOptions;
    };

    const handleToggleMenu = (expanded) => {
      setIsMenuExpanded(expanded);
    };

    return (
      <div className={`Container-cadastrar-noticia ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
        <div className="menu-cadastrar-noticia">
          <Menu onToggleMenu={handleToggleMenu} />
        </div>
        <div className="navbar-cadastrar-noticia">
          <NavBar />
        </div>
        <div className="main-cadastrar-noticia">
        {isLoading && <div className="loading-overlay active"><ReactLoading type={'spin'} color={'#121926'} height={80} width={80} /></div>}
          <form onSubmit={handleSubmit}>
            <div className="filters-cadastrar-noticia">
            {mediaError && <div className="error-message">{mediaError}</div>}
              {error && <div className="error-message">{error}</div>}
              {success && <div className="success-message">{success}</div>}
              <div className='dados-cadastrar-noticia'>
                <div className="search-container-cadastrar-noticia">
                  <div className="date-picker-cadastrar-noticia">
                    <div className="date-picker-label-cadastrar-noticia">Tipo de Fonte:</div>
                    <Select
                      value={sourceType}
                      onChange={setSourceType}
                      options={sourceTypeOptions}
                      styles={customStyles}
                      placeholder="Selecione o tipo de fonte"
                    />
                  </div>
                  {sourceType && (
                  <div className="date-picker-cadastrar-noticia">
                    <div className="date-picker-label-cadastrar-noticia">Estado:</div>
                    <Select
                      value={estado}
                      onChange={setEstado}
                      options={estadosOptions}
                      styles={customStyles}
                      placeholder="Selecione o estado"
                    />
                  </div>
                  )}
                  {estado && (
                  <div className="date-picker-cadastrar-noticia">
                    <div className="date-picker-label-cadastrar-noticia">Veículo</div>
                    <Select
                      value={veiculo}
                      onChange={setVeiculo}
                      options={veiculosOptions}
                      styles={customStyles}
                      placeholder="Digite para buscar veículo"
                      isDisabled={!sourceType}
                      onInputChange={handleVehicleInputChange}
                      inputValue={inputValue}
                      isLoading={isVehicleLoading}
                    />
                  </div>
                    )}
                </div>
                {sourceType && (sourceType.value === 'tv' || sourceType.value === 'radio') && veiculo && (
                  <div className="search-container-cadastrar-noticia">
                    <div className="date-picker-cadastrar-noticia">
                      <div className="date-picker-label-cadastrar-noticia">Programa:</div>
                      <Select
                        value={programa}
                        onChange={setPrograma}
                        options={programasOptions}
                        styles={customStyles}
                        placeholder="Selecione o programa"
                      />
                    </div>
                    <div className="date-picker-cadastrar-noticia">
                      <div className="date-picker-label-cadastrar-noticia">Apresentador:</div>
                      <Select
                        value={apresentador}
                        onChange={setApresentador}
                        options={apresentadoresOptions}
                        styles={customStyles}
                        isMulti
                        placeholder="Selecione os apresentadores"
                      />
                    </div>
                  </div>
                )}
                {sourceType && (sourceType.value === 'blog' || sourceType.value === 'site') && veiculo && (
                  <div className="search-text-cadastrar-noticia">
                    <div className="date-picker-cadastrar-noticia">
                      <div className="date-picker-label-cadastrar-noticia">Editoria:</div>
                      <Select
                        value={editoria}
                        onChange={setEditoria}
                        options={editoriasOptions}
                        styles={customStyles}
                        placeholder="Selecione a editoria"
                      />
                    </div>
                    <div className="date-picker-cadastrar-noticia">
                      <div className="date-picker-label-cadastrar-noticia">Autor:</div>
                      <Select
                        value={autor}
                        onChange={setAutor}
                        options={autoresOptions}
                        styles={customStyles}
                        isMulti
                        placeholder="Selecione os autores"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="informacao-cadastrar-noticia">
                {veiculo && (
                  <div className="search-midia-cadastrar-noticia">
                    <div className="date-midia-cadastrar-noticia">
                      <div className="date-midia-label-cadastrar-noticia">Mídias:</div>
                      <input 
                        type="file" 
                        id="fileInput"
                        onChange={handleFileChange} 
                        className="custom-midia-cadastrar-noticia"
                        multiple // Permitir múltiplos arquivos
                        style={{ display: 'none' }} 
                        ref={fileInputRef} // Adicionar a referência aqui
                      />
                      <button 
                        type="button" 
                        className="custom-file-button" 
                        onClick={() => document.getElementById('fileInput').click()}
                      >
                        Escolher arquivos
                      </button>
                      {previews.length > 0 && (
                            <div className="file-preview-container">
                            <div className="nav-buttons prev-button-container">
                            <button type="button" className="prev-button" onClick={handlePrevPreview}>&lt;</button>
                            </div>
                            <div className="file-preview">
                            {files[currentPreviewIndex].type.startsWith('image/') && (
                                <img src={previews[currentPreviewIndex]} alt={`Pré-visualização ${currentPreviewIndex}`} />
                            )}
                            {files[currentPreviewIndex].type.startsWith('video/') && (
                                <video src={previews[currentPreviewIndex]} controls></video>
                            )}
                            {files[currentPreviewIndex].type.startsWith('audio/') && (
                                <audio src={previews[currentPreviewIndex]} controls></audio>
                            )}
                            </div>
                            <div className="nav-buttons next-button-container">
                            <button type="button" className="next-button" onClick={handleNextPreview}>&gt;</button>
                            </div>
                        </div>
                      )}
                        {previews.length > 0 && (
                        <button 
                              type="button" 
                              className="clear-file-button" 
                              onClick={clearFiles}
                            >
                              &times;
                            </button>
                            )}
                    </div>
                  </div>
                )}
                {veiculo && (
                  <div className="search-informacao-cadastrar-noticia">
                      <div className="date-informacao-cadastrar-noticia">
                        <div className="date-informacao-label-cadastrar-noticia">Tipo de Notícia:</div>
                        <Select
                          value={mediaContentType}
                          onChange={(selectedOption) => {
                            setMediaContentType(selectedOption);
                          }}
                          options={mediaContentTypeOptions}
                          styles={customdropdown}
                          placeholder="Selecione o tipo de notícia"
                          className="custom-informacao-cadastrar-tipo-noticia"
                        />
                      </div>
                    {(sourceType && (sourceType.value === 'tv' || sourceType.value === 'radio')) && (
                      <div className="date-informacao-cadastrar-noticia">
                        <div className="date-informacao-label-cadastrar-noticia">Tempo da Mídia:</div>
                        <InputMask 
                          mask="99:99:99"
                          value={occupiedSpace} 
                          onChange={(e) => setOccupiedSpace(e.target.value)}
                          placeholder="hh:mm:ss" 
                          className="custom-informacao-cadastrar-noticia"
                        />
                      </div>
                    )}
                      <div className="date-informacao-cadastrar-noticia">
                        <div className="date-informacao-label-cadastrar-noticia">Título:</div>
                        <input 
                          type="text" 
                          value={titulo} 
                          onChange={(e) => setTitulo(e.target.value)} 
                          placeholder="Digite o título" 
                          className="custom-informacao-cadastrar-noticia"
                        />
                      </div>
                  </div>
                )}
              </div>
            </div>

            <div className="dados-e-materiais">
              <div className='dados-texto'>
                {veiculo && (
                  <div className="search-text-cadastrar-noticia">
                    <div className="date-text-cadastrar-noticia">
                      <div className="date-text-label-cadastrar-noticia">Resumo:</div>
                      <textarea 
                        value={resumo} 
                        onChange={(e) => setResumo(e.target.value)} 
                        placeholder="Digite o resumo"
                        className="custom-select-cadastrar-noticia textarea-resumo"
                        rows="5"
                      />
                    </div>
                  </div>
                )}
                {veiculo && (
                  <div className="search-text-cadastrar-noticia">
                    <div className="date-text-cadastrar-noticia">
                      <div className="date-text-label-cadastrar-noticia">Conteúdo:</div>
                      <textarea 
                        value={conteudo} 
                        onChange={(e) => setConteudo(e.target.value)} 
                        placeholder="Digite o conteúdo" 
                        className="custom-select-cadastrar-noticia textarea-conteudo"
                        rows="10"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className='materiais-cadastrar-noticia'>
                {veiculo && (
                  <div className="search-materiais-cadastrar-noticia">
                    <div className="date-materiais-cadastrar-noticia">
                      <div className="date-materiais-label-cadastrar-noticia">Data Publicação:</div>
                      <div className="date-time-container">
                        <div className="date-materiais-cadastrar-noticia">
                          <div className="date-materiais-label-cadastrar-noticia">Data:</div>
                          <DatePicker
                            selected={dataPublicacao}
                            onChange={(date) => setDataPublicacao(date)}
                            dateFormat="dd/MM/yyyy"
                            className="custom-materiais-cadastrar-noticia date-input"
                          />
                        </div>
                        <div className="date-materiais-cadastrar-noticia">
                          <div className="date-materiais-label-cadastrar-noticia">Hora:</div>
                          <DatePicker
                            selected={horaPublicacao}
                            onChange={(time) => setHoraPublicacao(time)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Hora"
                            dateFormat="HH:mm"
                            className="custom-materiais-cadastrar-noticia time-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {veiculo && (
                <div className="search-container-cadastrar-noticia">
                    <div className="date-picker-cadastrar-noticia">
                    <div className="date-picker-label-cadastrar-noticia">Categoria:</div>
                    <Select
                        value={categorias}
                        onChange={setCategorias}
                        options={categoriasOptions} // Usar opções carregadas
                        styles={customdropdown}
                        isMulti
                        placeholder="Selecione as categorias"
                    />
                    </div>
                </div>
                )}
              </div>
            </div>

            <div className='button-cadastrar-noticia'>
              <button type="submit" className='button-cadastrar'>
                Cadastrar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
}

export default CadastrarNoticia;
