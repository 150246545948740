import React, { useState, useEffect } from 'react';
import './TelaRedefinicaoSenha.css';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import { changePassword } from '../../services/api/fetchUser';
import { fetchUser } from '../../services/api/fetchUser';
import { useParams } from 'react-router-dom';

function TelaRedefinicaoSenha({ onVoltar }) {
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userName, setUserName] = useState(''); // Adiciona estado para o nome do usuário
  const [userData, setUserData] = useState({});
  const { clientId } = useParams();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    const getUserName = async () => {
      try {
        const response = await fetchUser(clientId); // Passa o clientId para a função de busca de usuário
        setUserName(response.name); // Ajusta isso de acordo com a estrutura da resposta da sua API
      } catch (error) {
        console.error('Erro ao buscar o nome do usuário:', error);
      }
    };

    getUserName();
  }, [clientId]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await fetchUser();
        setUserData(user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    getUserData();
  }, []);
  
  const handleSave = async () => {
    if (novaSenha !== confirmarNovaSenha) {
      setErrors({ confirmarNovaSenha: 'A nova senha e a confirmação de senha não coincidem.' });
      return;
    }

    try {
      const response = await changePassword(senhaAntiga, novaSenha, confirmarNovaSenha);
      setSuccessMessage('Senha alterada com sucesso!');
      setErrors({});
      setErrorMessage(''); // Limpar mensagem de erro
      setTimeout(() => setSuccessMessage(''), 5000); // Limpar mensagem após 5 segundos
      // Lógica adicional após a mudança de senha, se necessário
    } catch (error) {
      console.error('Erro ao alterar a senha:', error);
      const errorData = error.response?.data || { general: 'Erro desconhecido.' };
      setErrors(errorData);
      setErrorMessage('Erro ao alterar a senha.');
      setSuccessMessage(''); // Limpar mensagem de sucesso
      setTimeout(() => setErrorMessage(''), 5000); // Limpar mensagem após 5 segundos
    }
  };

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
  };

  return (
    <div className={`container-redefinicao-senha ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      <div className="menu-dados-senha">
        <Menu onToggleMenu={handleToggleMenu} />
      </div>
      <div className="navbar-dados-senha">
        <NavBar />
      </div>
      <div className="main-dados-senha">
        <div className='redefinicao-senha'>
          <div className="div-2-redefinicao-senha">
            <div className="column-redefinicao-senha">
              <img
                loading="lazy"
                src={userData.photo ? userData.photo : require('../../assets/nxt_logo.jpg')}
                className="img-redefinicao-senha"
              />
            </div>
            <div className="column-2-redefinicao-senha">
              <div className="div-3-redefinicao-senha">
                <div className="div-4-redefinicao-senha">{userName}</div> {/* Exibe o nome do usuário */}
                {/* <div className="div-5-redefinicao-senha">Trocar Foto</div> */}
              </div>
            </div>
          </div>
        </div>

        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}

        <div className='input-redefinicao-senha'>
          <div className="date-picker-redefinicao-senha">
            <div className="date-picker-label-redefinicao-senha">Senha Antiga</div>
            <input
              type="password"
              className="custom-select-redefinicao-senha"
              placeholder="Digite o texto aqui"
              value={senhaAntiga}
              onChange={(e) => setSenhaAntiga(e.target.value)}
            />
            {errors.old_password && <div className="error-message">{errors.old_password[0]}</div>}
          </div>

          <div className="date-picker-redefinicao-senha">
            <div className="date-picker-label-redefinicao-senha">Nova Senha</div>
            <input
              type="password"
              className="custom-select-redefinicao-senha"
              placeholder="Digite o texto aqui"
              value={novaSenha}
              onChange={(e) => setNovaSenha(e.target.value)}
            />
            {errors.password1 && <div className="error-message">{errors.password1[0]}</div>}
          </div>

          <div className="date-picker-redefinicao-senha">
            <div className="date-picker-label-redefinicao-senha">Confirmar Nova Senha</div>
            <input
              type="password"
              className="custom-select-redefinicao-senha"
              placeholder="Digite o texto aqui"
              value={confirmarNovaSenha}
              onChange={(e) => setConfirmarNovaSenha(e.target.value)}
            />
            {errors.password2 && <div className="error-message">{errors.password2[0]}</div>}
          </div>
        </div>

        <div className='Buttons-redefinicao-senha'>
          <button className='voltar-redefinicao-senha' onClick={onVoltar}>
            Voltar
          </button>

          <button className='salvar-redefinicao-senha' onClick={handleSave}>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}

export default TelaRedefinicaoSenha;
