import React, { useState, useEffect, useRef, useCallback } from 'react';
import Hls from 'hls.js';
import './VideoPlayer.css';
import { FaVolumeUp, FaVolumeMute, FaPlay, FaPause, FaExpand, FaCompress, FaFastForward        } from 'react-icons/fa';

const VideoPlayer = ({ link, startSeconds, endSeconds }) => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isScreenWide, setIsScreenWide] = useState(window.innerWidth > 1025);
  const duration = endSeconds - startSeconds;
  const [mediaType, setMediaType] = useState('tv'); // default to 'tv'
  const [playbackRate, setPlaybackRate] = useState(1);

useEffect(() => {
  if (link.includes('radio')) {
    setMediaType('radio');
  } else if (link.includes('tv')) {
    setMediaType('tv');
  }
}, [link]);


  const handlePlayPause = useCallback((event) => {
    event.stopPropagation();
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play().then(() => {
          console.log('Vídeo reproduzido manualmente');
          setIsPlaying(true);
        }).catch(error => {
          console.error('Erro ao reproduzir o vídeo:', error);
        });
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  const handleTimeUpdate = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      const adjustedCurrentTime = video.currentTime - startSeconds;
      setCurrentTime(adjustedCurrentTime);
      if (video.currentTime >= endSeconds) {
        video.pause();
        setIsPlaying(false);
      }
    }
  }, [startSeconds, endSeconds]);

  const handleSeek = useCallback((e) => {
    const video = videoRef.current;
    const seekTime = (duration / 100) * e.target.value;
    video.currentTime = startSeconds + Math.min(seekTime, duration);
  }, [duration, startSeconds]);

  const handleMuteToggle = useCallback((event) => {
    event.stopPropagation();
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  }, [isMuted]);

  const handleFullscreenToggle = useCallback(() => {
    const video = videoRef.current;
    if (video) {
      if (!isFullscreen) {
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.mozRequestFullScreen) { /* Firefox */
          video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE/Edge */
          video.msRequestFullscreen();
        } else if (video.webkitEnterFullscreen) { /* iOS Safari */
          video.webkitEnterFullscreen();
        }
      } else {
        if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
          }
        } else if (video.webkitExitFullscreen) { /* iOS Safari */
          video.webkitExitFullscreen();
        }
      }
    }
  }, [isFullscreen]);

  const handleFullscreenChange = useCallback(() => {
    setIsFullscreen(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  }, []);

  const formatTime = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleLoadedMetadata = () => {
      const video = videoRef.current;
      if (video) {
        video.currentTime = startSeconds;
        video.play().then(() => {
          console.log('Reprodução automática iniciada');
          setIsPlaying(true);
        }).catch(error => {
          console.error('Erro ao reproduzir o vídeo:', error);
        });
        setIsLoading(false);
      }
    };

    const handleResize = () => {
      setIsScreenWide(window.innerWidth > 1025);
    };

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(link);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = link;
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    video.addEventListener('timeupdate', handleTimeUpdate);
    window.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, [link, startSeconds, handleTimeUpdate, handleFullscreenChange]);

  const handlePlaybackRateToggle = useCallback((event) => {
    event.stopPropagation(); // Para evitar que o vídeo pare
    const video = videoRef.current;
    const newRate = playbackRate >= 2 ? 1 : playbackRate + 0.5;
    if (video) {
      video.playbackRate = newRate;
    }
    setPlaybackRate(newRate);
  }, [playbackRate]);

  return (
    <div className={`video-container ${mediaType}`} onClick={handlePlayPause}>
      {isLoading && mediaType === 'tv' && <div className="spinner tv-spinner"></div>} 
      
      <video
        ref={videoRef}
        className={`video-js vjs-default-skin ${mediaType}`}
        preload="auto"
        width="640"
        height="360"
        playsInline
        webkit-playsinline="true"
        onClick={(e) => e.stopPropagation()}
      />

      <div className="video-controls">
        {isLoading && mediaType === 'radio' ? (
          <div className="spinner play-pause-spinner"></div> 
        ) : (
          <button className="play-pause-button" onClick={handlePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>
        )}
        
        <div className="volume-control" onClick={handleMuteToggle}>
          {isMuted ? <FaVolumeMute className="volume-icon" /> : <FaVolumeUp className="volume-icon" />}
        </div>

        <div className="playback-rate-toggle" onClick={handlePlaybackRateToggle}>
          <button className="playback-rate-button">
            <FaFastForward     unning   style={{ marginRight: '5px' }} />
            {playbackRate}x
          </button>
        </div>
        
        <div className="time-display">
          <span className="current-time">{formatTime(currentTime)}</span>
          <span className="total-time"> / {formatTime(duration)}</span>
        </div>
        
        <input
          type="range"
          min="0"
          max="100"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleSeek}
          disabled={isLoading && mediaType === 'radio'} 
        />
        
        {isScreenWide || mediaType === 'tv' ? (
          <button className="fullscreen-button" onClick={handleFullscreenToggle}>
            {isFullscreen ? <FaCompress /> : <FaExpand />}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default VideoPlayer;
