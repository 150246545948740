import React, { useState } from 'react';
import './PesquisaAvancada.css';
import NavBar from '../../components/navbar/NavBar';
import Menu from '../../components/menu/Menu';
import BuscaAvancada from '../BuscaAvançada/BuscaAvancada';
import { useLocation } from 'react-router-dom';

function PesquisaAvancada() {
  const location = useLocation();
  const initialTab = location.state?.activeTab || 'tab1';
  const [activeTab, setActiveTab] = useState(initialTab);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const handleToggleMenu = (expanded) => {
    setIsMenuExpanded(expanded);
  };

  return (
    <div className={`container-pesquisa ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
      <div className="menu-pesquisa">
        <Menu onToggleMenu={handleToggleMenu} />
      </div>
      <div className="navbar-pequisa">
        <NavBar />
      </div>

      <div className="main-pesquisa">
        <div className="tab-content">
          {activeTab === 'tab1' && <BuscaAvancada />}
        </div>
      </div>
    </div>
  );
}

export default PesquisaAvancada;
