import React, { useEffect, useState } from 'react';
import './EditarClipping.css';
import Menu from '../../components/menu/Menu';
import NavBar from '../../components/navbar/NavBar';
import { Link } from 'react-router-dom';
import FilterSearch from '../../components/FilterSearch/FilterSearch';
import { fetchClippingById } from '../../services/api/fetchClipping';
import { updateClippingCategories } from '../../services/api/fetchClipping';
import { fetchSources } from '../../services/api/fetchSources';
import { fetchCategories } from '../../services/api/fetchCategories';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

const formatDateTime = (dateTimeString) => {
    const [date, time] = dateTimeString.split(' ');
    return { date, time };
};

function EditarClipping() {
    const [clippingData, setClippingData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sources, setSources] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const clippingId = localStorage.getItem('clippingId');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { clientId } = useParams();
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '95%',
            padding: '10px',
            fontSize: '14px',
            borderColor: state.isFocused ? '#ccc' : '#ccc',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: '#333',
            outline: 'none',
            boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
            transition: 'border-color 0.3s, box-shadow 0.3s',
            '&:hover': {
                borderColor: '#ccc',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#eee' : '#fff',
            color: '#333',
            padding: '10px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 2,
        }),
    };

    useEffect(() => {
        const clippingId = localStorage.getItem('clippingId');
    
        if (clippingId) {
            const getClippingData = async () => {
                try {
                    const clipping = await fetchClippingById(clippingId);
                    if (clipping) {
                        setClippingData(clipping);
                        const { date, time } = formatDateTime(clipping.publish_date);
                        setStartDate(date);
                        setEndDate(time);
                        setSelectedCategories(clipping.categories.map(category => ({ value: category.id, label: category.name })));
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados do clipping:', error);
                } finally {
                    setLoading(false);
                }
            };
            getClippingData();
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const getSources = async () => {
            try {
                const sourcesData = await fetchSources();
                setSources(sourcesData);
            } catch (error) {
                console.error('Erro ao buscar fontes:', error);
            }
        };
        getSources();
    }, []);

    useEffect(() => {
        const getCategories = async () => {
            try {
                const categoriesData = await fetchCategories(clientId);
                console.log('Categories data:', categoriesData); // Log categorias recebidas
                setCategories(categoriesData);
            } catch (error) {
                console.error('Erro ao buscar categorias:', error);
            }
        };
        getCategories();
    }, [clientId]);

    const handleCategoryChange = (selectedOptions) => {
        setSelectedCategories(selectedOptions);
        console.log("Selected Categories:", selectedOptions); // Adicione este console.log para ver as categorias selecionadas
    };
    

    if (!clippingId) {
        return <p>Clipping não encontrado.</p>;
    }

    const handleSave = async () => {
        console.log('clippingId:', clippingId); // Verificar o valor do clippingId
        if (!clippingId) {
            console.error('ID do clipping não encontrado');
            setErrorMessage('ID do clipping não encontrado');
            setTimeout(() => setErrorMessage(''), 5000); // Limpar mensagem após 5 segundos
            return;
        }
        
        const selectedCategoryIds = selectedCategories.map(category => category.value); // Extrair apenas os IDs
        console.log('Selected Category IDs:', selectedCategoryIds); // Verificar os IDs das categorias selecionadas
        
        try {
            await updateClippingCategories(clippingId, selectedCategoryIds);
            console.log("Categorias atualizadas com sucesso");
            setSuccessMessage('Categorias atualizadas com sucesso');
            setErrorMessage(''); // Limpar mensagem de erro
            setTimeout(() => setSuccessMessage(''), 5000); // Limpar mensagem após 5 segundos
            // Adicione lógica adicional aqui se necessário, como redirecionamento ou exibição de mensagens de sucesso
        } catch (error) {
            console.error('Erro ao atualizar categorias do clipping:', error);
            setErrorMessage('Erro ao atualizar categorias do clipping');
            setSuccessMessage(''); // Limpar mensagem de sucesso
            setTimeout(() => setErrorMessage(''), 5000); // Limpar mensagem após 5 segundos
        }
    };

    const handleToggleMenu = (expanded) => {
        setIsMenuExpanded(expanded);
      };

    return (
        <div className={`editar-clipping ${isMenuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
            <div className="menu-editar-clipping">
                <Menu onToggleMenu={handleToggleMenu} />
            </div>
            <div className="navbar-editar-clipping">
                <NavBar />
            </div>
            {!loading && clippingData && (
                <div className="editar-clipping-container">
                    <header className="editar-clipping-header">
                        <h1 className="editar-clipping-title">
                            <Link to={`/${clientId}/visualizacao/${clippingId}`} className="editar-clipping-home-link">
                                <img
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5929b77c00679695d19f92f7e32c26c91de2a2ee22bd02668e17a8ec96b8ff1?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d"
                                    alt=""
                                    className="editar-clipping-icon"
                                />
                            </Link>
                            <span className="editar-clipping-title-text">Conteúdo do Clipping</span>
                        </h1>
                    </header>
    
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
    
                    <div className='dados-editar-clipping'>
                        <div className='dados-clipping-item'>
                            <span className='dados-clipping-label'>Título:</span> {clippingData.title}
                        </div>
                        <div className='dados-clipping-item'>
                            <span className='dados-clipping-label'>Veículo:</span> {clippingData.source_detail.name}
                        </div>
                        <div className='dados-clipping-item'>
                                <span className='dados-clipping-label'>
                                    {clippingData.source_detail.source_type === 'tv' || clippingData.source_detail.source_type === 'radio' ? 'Programa:' : 'Editorial:'}
                                </span> 
                                {clippingData.editorial}
                            </div>
                            <div className='dados-clipping-item'>
                                <span className='dados-clipping-label'>
                                    {clippingData.source_detail.source_type === 'tv' || clippingData.source_detail.source_type === 'radio' ? 'Apresentador:' : 'Autor:'}
                                </span> 
                                {clippingData.authors}
                            </div>
                    </div>
    
                    <hr className='hr-horizontal-editar-clipping'></hr>
                    <div className='resumo-clipping'>
                        <div className='dados-resumo-clipping'>
                            <span className='dados-resumo-clipping-label'>Resumo:</span>
                            <span className='resume-label'>{clippingData.resume}</span>
                        </div>
                    </div>
    
                    <div className='alterar-clipping'>
                    <div className='texto-clipping'>
                        <h1>Conteúdo do Clipping:</h1>
                        <div className='text-clipping' dangerouslySetInnerHTML={{ __html: clippingData.content }}>
                        </div>
                    </div>
    
                        <div className='dados-clipping'>
                            <div className='filter-search-editar-clipping'>
                                <div className='category-select'>
                                    <label>Selecione as Categorias:</label>
                                    <Select
                                        isMulti
                                        options={categories.map(category => ({ value: category.id, label: category.name }))}
                                        onChange={handleCategoryChange}
                                        className='dropdown-categorias'
                                        styles={customStyles}
                                        placeholder='Selecione a categoria.'
                                        value={selectedCategories}
                                    />
                                </div>
                            </div>
    
                            <div className='Data-editar-clipping'>
                                <div className="date-picker-search-editar-clipping">
                                    <div className="date-picker-label-search-editar-clipping">Data Publicação</div>
                                    <div className="date-picker-header-search-editar-clipping">
                                        <div className="date-picker-content-search-editar-clipping">
                                            <InputMask
                                                mask="99/99/9999"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                className="selected-date-input-editar-clipping inativo"
                                                placeholder='DD/MM/AAAA'
                                                readOnly
                                            />
                                        </div>
                                        <img
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                                            alt=""
                                            className="calendar-icon-search-editar-clipping"
                                        />
                                    </div>
                                </div>
    
                                <div className="date-picker-search-editar-clipping">
                                    <div className="date-picker-label-search-editar-clipping">Hora Publicação</div>
                                    <div className="date-picker-header-search-editar-clipping">
                                        <div className="date-picker-content-search-editar-clipping">
                                            <InputMask
                                                mask="99:99"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                className="selected-date-input-editar-clipping inativo"
                                                placeholder='HH:MM'
                                                readOnly
                                            />
                                        </div>
                                        <img
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c6cd33072230ae936f1e816d9aa1ba45453ba4d5a4f9099598c1801929589e2?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d&"
                                            alt=""
                                            className="calendar-icon-search-editar-clipping"
                                        />
                                    </div>
                                </div>
                            </div>
    
                            <div className='button-salvar-editar-clipping'>
                                <button onClick={handleSave}>Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EditarClipping;
