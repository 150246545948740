import React from 'react';
import './404.css';
import iconeErro from '../../assets/icone-erro.png';

const Erro404 = () => {
  return (
    <div className="erro404-container">
      <h1 class="display-1 mb-2">4
        <img src={iconeErro} alt="Emoji" height="140" width="140" class="custom-emoji"></img>
        4
      </h1>
      <h5 className='text-tela-error'>
        Oops! Página não encontrada.
      </h5>
      <h5 class="error-details">
          A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível.
      </h5>
      <div class="text-center">
      <a className="btn btn-secondary mt-5 mb-5" href={`${process.env.REACT_APP_LINK_URL}`}> <i className="fa fa-long-arrow-left"></i> Voltar ao Início </a>
      </div>
    </div>
  );
};

export default Erro404;