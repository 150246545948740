import React from 'react';
import './WordCloud.css';
import ReactWordcloud from 'react-wordcloud';

 export function WordCloud({words}) {
    const callbacks = {
        getWordColor: (word) => word.color,
        onWordClick: console.log,
        onWordMouseOver: console.log,
      }
      const options = {
        rotations: 2,
        rotationAngles: [-270, 0],
        fontWeight:"bold",
        fontSizes: [18, 40],
      };
      const size = [500, 400];
  
    return (
        <ReactWordcloud
        callbacks={callbacks}
        options={options}
        size={size}
        words={words}
      />
    );
  }
  
  export default WordCloud;