import { post, del, get, patch } from './generic.api';

export const changePassword = async (oldPassword, newPassword, confirmNewPassword) => {
    try {
      const response = await post('/users/change_password/', {
        old_password: oldPassword,
        password1: newPassword,
        password2: confirmNewPassword,
      });
      return response;
    } catch (error) {
      console.error('Erro na solicitação de alteração de senha:', error.response);
      throw error;
    }
  };

  export const fetchImage = async (imageFile) => {
    const formData = new FormData();
    formData.append('photo', imageFile);
  
    try {
      const response = await post('/users/change_photo/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error.response);
      throw error;
    }
  };

  export const fetchUser = async () => {
    return await get('/users/me/');
  };

  export const updateUser = async (userData) => {
    try {
      const response = await patch('/users/me/', userData);
      return response;
    } catch (error) {
      console.error('Erro ao atualizar os dados do usuário:', error.response);
      throw error;
    }
  };