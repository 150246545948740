import React from 'react';
import './confirmModal.css';

const ConfirmModal = ({ title, message, onConfirm, onCancel }) => {
    return (
        <div className="confirm-modal-overlay">
            <div className="confirm-modal">
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="confirm-modal-buttons">
                    <button className="confirm-button" onClick={onConfirm}>Confirmar</button>
                    <button className="cancel-button" onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
