import React from 'react';
import './TableChart.css';

export function TableChart({ dataPoints }) {
    return (
        <div className="table-chart-container">
            <table className="table-chart">
                <thead>
                    <tr>
                        <th>Dados</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {dataPoints.map((data, index) => (
                        <tr key={index}>
                            <td>{data.label || data.text}</td>
                            <td>{data.y || data.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableChart;
