import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}api`;

// Configuração do Axios sem autenticação
const publicAxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Função para buscar clipping por ID sem autenticação
export const fetchPublicClippingById = async (clippingId) => {
  try {
    const response = await publicAxiosInstance.get(`/clipping/${clippingId}/public_clipping/`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar clipping público pelo ID:', error);
    throw error;
  }
};
