import React from 'react';
import './CardFiltrosAssuntos.css';

const CategoryButton = ({ category, count, onClick }) => {
  const imageUrl = "https://cdn.builder.io/api/v1/image/assets/TEMP/736c6f6b-4ec8-4f0b-8274-9dda5293da83?apiKey=c7cb0dec5bf7436eb48ab9b2fb417d9d";

  return (
    <div className="category-button" onClick={() => onClick(category)}>
      <div className="category-content">
        <img src={imageUrl} alt={category} className="category-image" />
        <div className="category-details">
          <span className="category-name">{category}</span>
          <span className="category-count">({count})</span>
        </div>
      </div>
    </div>
  );
};

export default CategoryButton;
