import { post, del, get } from './generic.api';

export const fetchSources = async ( searchTerm = "") => {
    try {
      const response = await get(`/source/`, {
          name__icontains: searchTerm,
          is_active: true, // Adicionando o parâmetro is_active
      });
      return response.results;
    } catch (error) {
      console.error('Erro ao buscar veículos:', error.response);
      throw error;
    }
  };

  export const fetchVehicles = async (searchTerm, sourceType, stateId, pageSize = 2000) => {
    try {
      const response = await get('/source/', {
          name__icontains: searchTerm,
          source_type: sourceType,
          state: stateId, // Adiciona o estado à requisição
          is_active: true,
          page_size: pageSize, 
      });
      return response.results;
    } catch (error) {
        console.error('Erro ao buscar veículos:', error.response);
      throw error;
    }
  };