import React, { useState, useEffect } from 'react';
import './FilterAvaliacao.css';
import Select from 'react-select';
import { fetchGroupers } from '../../services/api/fetchGrouper';
import InputMask from 'react-input-mask';

function FilterAvaliacao({ clientId, clippingId, onClose, onAddFilter, loadExistingData }) {
  const [groupers, setGroupers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const getGroupers = async () => {
      try {
        const response = await fetchGroupers(clientId);
        const sortedGroupers = response.sort((a, b) => a.id - b.id);
        setGroupers(sortedGroupers);

        if (loadExistingData) {
          const evaluationData = await fetchGroupers(clientId);
          const initialValues = {};
          evaluationData.forEach(item => {
            if (clippingId.includes(item.id)) {
              initialValues[item.id] = item.value;
            }
          });
          setSelectedValues(initialValues);
        }

        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar groupers:', error);
        setLoading(false);
      }
    };

    getGroupers();
  }, [clientId, clippingId, loadExistingData]);

  const handleInputChange = (id, value) => {
    setSelectedValues({
      ...selectedValues,
      [id]: value
    });
  };

  const handleAddFilter = () => {
    const filterData = groupers.map(grouper => ({
      grouper_detail: { id: grouper.id, name: grouper.name },
      value: selectedValues[grouper.id] || ''
    }));

    onAddFilter(filterData);
    onClose();
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      padding: '10px',
      fontSize: '14px',
      borderColor: state.isFocused ? '#ccc' : '#ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
      transition: 'border-color 0.3s, box-shadow 0.3s',
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      color: '#333',
      padding: '10px',
      '&:active': {
        backgroundColor: '#eee',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  if (loading) {
    return null;
  }

  return (
    <section className="container_avaliacao-filter-avaliacao">
      <button className="close-button_avaliacao-filter-avaliacao" aria-label="Close" onClick={onClose}>x</button>
      <h1 className="title_avaliacao-filter-avaliacao">Avaliação</h1>
      <hr className='hr-filter-avaliacao'></hr>

      <div className="grid_avaliacao-filter-avaliacao">
        {groupers.map(grouper => (
          <div key={grouper.id} className="date-picker-filters-avaliacao-filter-avaliacao">
            <div className="date-picker-label-filters-avaliacao-filter-avaliacao">{grouper.name}</div>
            {grouper.field_type === 'select' && (
              <Select
                styles={customStyles}
                value={selectedValues[grouper.id] ? { value: selectedValues[grouper.id], label: selectedValues[grouper.id] } : null}
                onChange={(selectedOption) => handleInputChange(grouper.id, selectedOption ? selectedOption.value : '')}
                options={grouper.values_list.map(option => ({
                  value: option.value,
                  label: option.value
                }))}
                placeholder="Todas"
              />
            )}
            {grouper.field_type === 'radio' && (
              grouper.values_list.map((option, index) => (
                <div key={index} className="feature_avaliacao-filter-avaliacao">
                  <input
                    type="radio"
                    id={`${grouper.id}-${index}`}
                    name={`feature-${grouper.id}`}
                    value={option.value}
                    className="radio-item_avaliacao-filter-avaliacao"
                    checked={selectedValues[grouper.id] === option.value}
                    onChange={(e) => handleInputChange(grouper.id, e.target.value)}
                  />
                  <label htmlFor={`${grouper.id}-${index}`} className="feature-label_avaliacao-filter-avaliacao">
                    {option.value}
                  </label>
                </div>
              ))
            )}
              {grouper.field_type === 'number' && (
                <div className="input-wrapper">
                  {selectedValues[grouper.id] && <span className="currency-prefix">R$</span>}
                  <input
                    type="number"
                    className="custom-select-tema-filter-avaliacao"
                    placeholder="Digite a valoração"
                    value={selectedValues[grouper.id] || ''}
                    onChange={(e) => handleInputChange(grouper.id, e.target.value)}
                    style={{ paddingLeft: selectedValues[grouper.id] ? '2rem' : '0.5rem' }} // Ajusta o espaçamento para o prefixo
                  />
                </div>
              )}
            {grouper.field_type === 'text' && (
              <input
                type="text"
                className="custom-select-tema-filter-avaliacao"
                placeholder="Texto"
                value={selectedValues[grouper.id] || ''}
                onChange={(e) => handleInputChange(grouper.id, e.target.value)}
              />
            )}
          </div>
        ))}
      </div>

      {message && <p className="success-message-filter-avaliacao">{message}</p>}
      {error && <p className="error-message-filter-avaliacao">{error}</p>}

      <button className="block-rate_avaliacao-filter-avaliacao" aria-label="Avaliar noticias em bloco" onClick={handleAddFilter}>
        Adicionar filtros de avaliação
      </button>
      <button className="cancel-button_avaliacao-filter-avaliacao" aria-label="Cancelar" onClick={onClose}>
        Cancelar
      </button>
    </section>
  );
}

export default FilterAvaliacao;
