import React from 'react';
import './AreaChart.css';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

 export function AreaChart({dataPoints}) {
  const options = {
    
    width: 500,
    height: 400,
    backgroundColor: "transparent",
    axisY: {
     interval: 20,
    },
    axisX:{
      interval: 1,
      valueFormatString: "DD",
    },
    animationEnabled: false,
    data: [{
      indexLabelFontSize:10,
      indexLabel: '{y}',
      indexLabelFontColor: "red",
      markerSize: 0,
      type: "area",
      dataPoints: dataPoints
    }]
  }

    return (
     <div>
<CanvasJSChart options = {options}
          /* onRef = {ref => this.chart = ref} */
        />
     </div>
    );
  }
  
  export default AreaChart;