import { post, del, get } from './generic.api';

export const fetchCities = async () => {
    const response = await get('/city/');
    return response;
  };

  export const fetchStates = async () => {
    const response = await get('/state/');
    return response;
  };