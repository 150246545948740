
function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const Reports = [{
    id: 1,
    name: "Relatório de Análise Crítica",
}]

export const MockReportData = {

    reviewQualityPage : {
            type: 'donut',
            data: [{ y: 1137, label: "Positiva", color: "#005973" }, { y: 250, label: "Neutra", color: "#ffc20e" }, { y: 23, label: "Negativa", color: "#ee3124" }]
    }

    , reviewTextTypePage : {

            type: 'donut',
            data: [{ y: 1319, label: "Matéria", color: "#005973" },
            { y: 68, label: "Coluna", color: "#ffc20e" },
            { y: 13, label: "Entrevista", color: "#a7bc50" },
            { y: 5, label: "Debate", color: "#6c92f3" },
            { y: 2, label: "Chamada de Capa", color: "#ee3124" },
            { y: 2, label: "Debate", color: "#a3a5a8" },
            { y: 1, label: "Nota", color: "#158f80" }]
        
    }


    , reviewMentionPage : {

            type: 'donut',
            data: [{ y: 100, label: "Direta", color: "#005973" },
            { y: 52, label: "Indireta", color: "#ffc20e" },
            { y: 52, label: "Neutra", color: "#ee3124" }]
        
    }
    , reviewWordCloudPage : {

            type: 'wordcloud',
            data: [
                {value:190 ,text: 'Mandado De Prisão', color:getRandomColor()},
                {value:134 ,text: 'Decisões Judiciais', color:getRandomColor()},
                {value:74 ,text: 'Funcionamento Do Tjpe', color:getRandomColor()},
                {value:60 ,text: 'Juiz', color:getRandomColor()},
                {value:59 ,text: 'Desembargador', color:getRandomColor()},
                {value:55 ,text: 'Processos Judiciais', color:getRandomColor()},
                {value:54 ,text: 'Leilão De Imóveis', color:getRandomColor()},
                {value:53 ,text: 'Escola Judicial De Per…', color:getRandomColor()},
                {value:50 ,text: 'Audiência De Custódia', color:getRandomColor()},
                {value:47 ,text: 'Semana Nacional Do R…', color:getRandomColor()},
                {value:41 ,text: 'Casamento Comunitário', color:getRandomColor()},
                {value:41 ,text: 'Programa Moradia Legal', color:getRandomColor()},
                {value:41 ,text: 'Semana Estadual De C…', color:getRandomColor()},
                {value:40 ,text: 'Comitê Juntos Pela Se…', color:getRandomColor()},
                {value:31 ,text: 'Adoção', color:getRandomColor()},
                {value:28 ,text: 'Ajuda Humatinária', color:getRandomColor()},
                {value:28 ,text: 'Campanha Sinal Verme…', color:getRandomColor()},
                {value:24 ,text: 'Participação Em Evento', color:getRandomColor()},
                {value:23 ,text: 'Canais De Comunicaçã…', color:getRandomColor()},
                {value:22 ,text: 'Juíza', color:getRandomColor()},
                {value:20 ,text: 'Política De Alternativ…', color:getRandomColor()},
                {value:19 ,text: 'Juizado Do Forró', color:getRandomColor()},
                {value:18 ,text: 'Centro De Estudos Ju…', color:getRandomColor()},
                {value:15 ,text: 'Caso Beatriz', color:getRandomColor()},
                {value:14 ,text: 'Justiça Itinerante', color:getRandomColor()},
                {value:13 ,text: 'Ação Da Cidadania', color:getRandomColor()},
                {value:13 ,text: 'Homenagem', color:getRandomColor()},
                {value:13 ,text: 'Posses', color:getRandomColor()},
                {value:13 ,text: 'Varas De Violência Co…', color:getRandomColor()},
                {value:12 ,text: 'Ação Afirmativa De Gê…', color:getRandomColor()},
                {value:12 ,text: 'Coordenadoria De Infâ…', color:getRandomColor()},
                {value:10 ,text: 'Caso Miguel', color:getRandomColor()},
                {value:10 ,text: 'Mês Estadual Do Júri', color:getRandomColor()},
                {value:10 ,text: 'Visita Ao Tjpe', color:getRandomColor()},
                {value:9 ,text: 'Leilão Judicial', color:getRandomColor()}]
        
    }

    , reviewMediaDistributionPage : {
     
            type: 'donut',
            data: [{ y: 659, label: "Site", color: "#005973" }, { y: 452, label: "Blog", color: "#ffc20e" }]
        
    }

    , reviewOcupedSpacePage :{
        data:[
            { type: 'TV', value: '04:31:41' },
            { type: 'WEB', value: '84510' + ' centrimetros' },
            { type: 'Impresso', value: '5027' + ' centrimetros' },
            { type: 'Rádio', value: '08:09:24' }
        ]}

    , reviewQualityOcupedSpacePage :{
       data:[
            { type: 'TV', value: '04:31:41', data: [{ y: 409, label: "Direta", color: "#005973" }, { y: 50, label: "Indireta", color: "#ffc20e" }, { y: 52, label: "Neutra", color: "#ee3124" }] },
            { type: 'WEB', value: '84510' + ' centrimetros', data: [{ y: 409, label: "Direta", color: "#005973" }, { y: 50, label: "Indireta", color: "#ffc20e" }, { y: 52, label: "Neutra", color: "#ee3124" }] },
            { type: 'Impresso', value: '5027' + ' centrimetros', data: [{ y: 409, label: "Direta", color: "#005973" }, { y: 50, label: "Indireta", color: "#ffc20e" }, { y: 52, label: "Neutra", color: "#ee3124" }] },
            { type: 'Rádio', value: '08:09:24', data: [{ y: 409, label: "Direta", color: "#005973" }, { y: 50, label: "Indireta", color: "#ffc20e" }, { y: 52, label: "Neutra", color: "#ee3124" }] }
        ]}
    

    , reviewMediaNewsPage : {
 
            type: 'bar',
            data: [{ y: 53, label: "DIÁRIO DE PERNAMBUCO ONLINE - DDDDDD", color: getRandomColor() },
            { y: 50, label: "PERNAMBUCO.COM - RECIFE - PE", color: getRandomColor() },
            { y: 43, label: "JORNAL DO COMMERCIO - PE", color: getRandomColor() },
            { y: 37, label: "JORNAL DO COMMERCIO - ON LINE DDDDDD", color: getRandomColor() },
            { y: 37, label: "RÁDIO JORNAL FM 90,3 - RECIFE DDDDDD", color: getRandomColor() },
            { y: 31, label: "TV GLOBO NORDESTE - RECIFE - PDDDDDD", color: getRandomColor() },
            { y: 29, label: "DIÁRIO DE PERNAMBUCO - RECIFE DDDDDD", color: getRandomColor() },
            { y: 27, label: "ORLANDO PASSOS - SANTA TEREZINDDDDDD", color: getRandomColor() },
            { y: 26, label: "FOLHA DE PERNAMBUCO ONLINE - R.DDDDDD", color: getRandomColor() },
            { y: 24, label: "FOLHA DE PERNAMBUCO - RECIFE -DDDDDD", color: getRandomColor() },
            { y: 23, label: "BLOG DO ALBERES XAVIER - PE", color: getRandomColor() },
            { y: 22, label: "G1 - PERNAMBUCO", color: getRandomColor() },
            { y: 20, label: "SPIAI.COM - CURITIBA - PR", color: getRandomColor() },
            { y: 19, label: "BLOG DO WAGNER GIL - CARUARU -DDDDDD", color: getRandomColor() },
            { y: 18, label: "CBN RECIFE ONLINE - PE", color: getRandomColor() },
            { y: 16, label: "BLOG DO NILL JUNIOR - AFOGADOSDDDDDD", color: getRandomColor() },
            { y: 15, label: "RÁDIO PANORAMA 87.9 FM - CUSTÓDDDDDD", color: getRandomColor() },
            { y: 14, label: "BLOG DO THIAGO LIMA - SALGUEIRDDDDDD", color: getRandomColor() },
            { y: 14, label: "BLOG PE NOTÍCIAS - PETROLINA -DDDDDD", color: getRandomColor() },
            { y: 13, label: "A TRIBUNA ONLINE - PE", color: getRandomColor() }]
        
    }

    , reviewMediaValuePage : {
    
            type: 'bar',
            data: [
                { y: 2737238.84, label: "TV JORNAL - AF. SBT - RECIFE -", color: getRandomColor() },
                { y: 1319604.58, label: "JORNAL DO COMMERCIO - PE", color: getRandomColor() },
                { y: 1081236.00, label: "TV TRIBUNA - AF. BAND - PE", color: getRandomColor() },
                { y: 1005569.09, label: "FOLHA DE PERNAMBUCO ONLINE - R", color: getRandomColor() },
                { y: 986120.00, label: "G1 - NACIONAL", color: getRandomColor() },
                { y: 513390.00, label: "TV GUARARAPES - AF. TV RECORD", color: getRandomColor() },
                { y: 510728.70, label: "TV BAND - NACIONAL", color: getRandomColor() },
                { y: 432661.32, label: "DIÁRIO DE PERNAMBUCO - RECIFE", color: getRandomColor() },
                { y: 374628.28, label: "FOLHA DE PERNAMBUCO - RECIFE -", color: getRandomColor() }
            ]
        
    }
    , reviewMediaPublishDataPage : {
   
            type: 'area',
            data: [{ x: new Date(2024, 1, 1), y: 23 },
            { x: new Date(2024, 1, 2), y: 10 },
            { x: new Date(2024, 1, 3), y: 54 },
            { x: new Date(2024, 1, 4), y: 2 },
            { x: new Date(2024, 1, 5), y: 4 },
            { x: new Date(2024, 1, 6), y: 25 },
            { x: new Date(2024, 1, 7), y: 34 },
            { x: new Date(2024, 1, 8), y: 65 },
            { x: new Date(2024, 1, 9), y: 14 },
            { x: new Date(2024, 1, 10), y: 23 },
            { x: new Date(2024, 1, 11), y: 49 },
            { x: new Date(2024, 1, 12), y: 49 },
            { x: new Date(2024, 1, 13), y: 20 },
            { x: new Date(2024, 1, 14), y: 23 },
            { x: new Date(2024, 1, 15), y: 43 },
            { x: new Date(2024, 1, 16), y: 21 },
            { x: new Date(2024, 1, 17), y: 32 },
            { x: new Date(2024, 1, 18), y: 42 },
            { x: new Date(2024, 1, 19), y: 52 },
            { x: new Date(2024, 1, 20), y: 52 },
            { x: new Date(2024, 1, 21), y: 55 },
            { x: new Date(2024, 1, 22), y: 11 },
            { x: new Date(2024, 1, 23), y: 6 },
            { x: new Date(2024, 1, 24), y: 15 },
            { x: new Date(2024, 1, 25), y: 25 },
            { x: new Date(2024, 1, 26), y: 33 },
            { x: new Date(2024, 1, 27), y: 11 },
            { x: new Date(2024, 1, 28), y: 15 },
            { x: new Date(2024, 1, 29), y: 16 },
            { x: new Date(2024, 1, 30), y: 33 },
            ]
        
    },

    reviewMapPage : {
            type: 'map',
            data: [{
                label: "PE", value: 1199, color: getRandomColor()
            },
            { label: "MT", value: 34, color: getRandomColor() },
            { label: "PR", value: 26, color: getRandomColor() },
            { label: "SP", value: 26, color: getRandomColor() },
            { label: "Nacional", value: 24, color: getRandomColor() },
            { label: "RJ", value: 14, color: getRandomColor() },
            { label: "DF", value: 14, color: getRandomColor() },
            { label: "BA", value: 8, color: getRandomColor() },
            { label: "RS", value: 8, color: getRandomColor() },
            { label: "AM", value: 6, color: getRandomColor() },
            { label: "MS", value: 5, color: getRandomColor() },
            { label: "RO", value: 5, color: getRandomColor() },
            { label: "PA", value: 5, color: getRandomColor() },
            { label: "SE", value: 5, color: getRandomColor() },
            { label: "SC", value: 4, color: getRandomColor() },
            { label: "ES", value: 4, color: getRandomColor() },
            { label: "AL", value: 4, color: getRandomColor() },
            { label: "GO", value: 3, color: getRandomColor() },
            { label: "MG", value: 3, color: getRandomColor() },
            { label: "PB", value: 3, color: getRandomColor() },
            { label: "TO", value: 2, color: getRandomColor() },
            { label: "AC", value: 2, color: getRandomColor() },
            { label: "PI", value: 2, color: getRandomColor() },
            { label: "CE", value: 2, color: getRandomColor() },
            { label: "MA", value: 1, color: getRandomColor() },
            { label: "RN", value: 1, color: getRandomColor() },
            ]
        
    }

}  